<div fxFlex="100%">
    <div fxFlex="25" class="side-menu-container">
        <app-side-menu [menus]="menus" (onMenuClick)="onItemClicked($event)"></app-side-menu>
    </div>
   
    <div fxFlex="80" style="padding-left: 55px">
        <div [hidden]="cameraChoosen">
            <div class="snapshot"><img [src]="profileImg" id="profile-pic"/> </div>
        </div>
        <div [hidden]="!cameraChoosen || (data && data.propertyImage)">
            <webcam [height]="250"  
            [trigger]="triggerObservable" 
                (imageCapture)="handleImage($event)"> 
            </webcam>
        </div>
    

        <!-- <div fxLayout="row" fxLayoutAlign="space-around center"> -->
            <div class="tbtn">
            <div [hidden]="cameraChoosen || (data && data.propertyImage)">
                    <button class="btn btn-primary actionBtn" (click)="chooseCamera()">Take Snap</button> 
                </div>
            <div [hidden]="!cameraChoosen || (data && data.propertyImage)">
                <button class="btn btn-primary actionBtn" (click)="triggerSnapshot()">Capture</button> 
            </div>
            <div [hidden]="data && data.propertyImage">
                <button class="btn btn-primary actionBtn" (click)="fetchFromGallery()"> Gallery</button> 
            </div>
            <div [hidden]="data && data.propertyImage">
                <button class="btn btn-primary actionBtn">  
                    <fa-icon [icon]="faTrash" class="close-icon" (click)="removePicture()"></fa-icon>
                </button> 
            </div>
        </div>
        <input type="file" id="image-gallery" accept="image/png, image/jpeg" (change)="onGalleryImageChoosen($event)" [hidden]="true">
        <!-- </div> -->
    
        <div style="width:50%"  class="row application-form-row">
            <label class="form-label"> ಜನ್ಮ ದಿನಾಂಕ / Date Of Birth : <span class="text-danger"> * </span></label>
            <input type="date" max="{{ maxDate |date:'yyyy-MM-dd'}}" onkeydown="return false" [(ngModel)]="applicantDob" class="form-textbox" [readonly] = "(data && data.applicantDob) && !isEditable"/>
        </div>
        <br>        
        <div style="width:60%"  class="row application-form-row">
        <label id="example-radio-group-label">ಲಿಂಗ/Gender : <span class="text-danger"> * </span></label>
        <div class="row radio-row">
            <input id="applicantGender" type="radio" [(ngModel)]="applicantGender" value="male" name="applicantGender" [disabled] = "(disableGender) && !isEditable"/><br>
            <label for="single" class="radio-text">ಗಂ/M</label>
        </div>
        <div  class="row radio-row">
             <input id="applicantGender" type="radio" [(ngModel)]="applicantGender" value="female" name="applicantGender" [disabled] = "(disableGender) && !isEditable"/><br>
             <label for="joint" class="radio-text">ಹೆಂ/ F </label>
    </div>
    <div  class="row radio-row">
        <input id="applicantGender" type="radio" [(ngModel)]="applicantGender" value="others" name="applicantGender" [disabled] = "(disableGender) && !isEditable"/><br>
        <label for="other" class="radio-text">ಇತರೆ/ Othersಲ</label>
    </div>
       
        </div>
    
    <div fxLayout="row" fxLayoutAlign="space-between">
        <div style="width: 50%"  class="row application-form-row">
            <label class="form-label"> ವಯಸ್ಸು /Age :  </label>
            <input type="number" [(ngModel)]="applicantAge" class="form-textbox" [readonly]="(data && data.applicantAge) && !isEditable" />
        </div>
        <div style="width: 50%"  class="row application-form-row">
            <label class="form-label"> ಉದ್ಯೋಗ / Occupation : </label>
            <input type="text" [(ngModel)]="applicantOccupation" class="form-textbox" [readonly]="(data && data.applicantOccupation) && !isEditable" />
        </div>
    </div>
    <br>
    <div class="title"> ವೈವಾಹಿಕ ಸ್ಥಾನ/ Marital Status: <span class="text-danger"> * </span> </div>
    <div class="col-sm-12 col-md-12"> <br>
        
        <div class="row radio-row">
            <input id="maritialStatus" type="radio" [(ngModel)]="maritialStatus"  name="maritialStatus" value="married" [disabled] = "disableMartialStatus && !isEditable" /><br>
            <label for="single" class="radio-text"> Married </label>
        </div>
        <div  class="row radio-row">
             <input id="maritialStatus" type="radio" [(ngModel)]="maritialStatus" name="maritialStatus" value="unmarried" [disabled] = "disableMartialStatus && !isEditable"/><br>
            <label for="joint" class="radio-text"> Unmarried </label>
        </div>
    </div>
    <div class="row application-form-row">
        <label class="form-label">ಆಧಾರ್ ಸಂಖ್ಯೆ/ AADHAR No (12 digits) : <span class="text-danger"> * </span></label>
        <div class="form-label" style="font-style: italic;">for Passport use prefix PP and for Voter ID use prefix VI.Mandatory 12 characters, 
                use "-" for empty spaces</div>
        <input type="text" [(ngModel)]="aadharNo" class="form-textbox" minlength="12" maxlength="12" [readonly]="(data && data.aadharNo) && !isEditable" />
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between">
        <div style="width: 50%"  class="row application-form-row">
            <label class="form-label"> ಪ್ಯಾನ್ ಸಂಖ್ಯೆ (ಲಭ್ಯವಿದ್ದಲ್ಲಿ) / PAN No (if any) : </label>
            <input type="text" [(ngModel)]="panNo" class="form-textbox" [readonly]="(data && data.panNo) && !isEditable" />
        </div>
        <div style="width: 50%"  class="row application-form-row">
            <label class="form-label"> ವಾರ್ಷಿಕ ಆದಾಯ/ Annual Income (In Rupees):  </label>
            <input type="number" [(ngModel)]="annualIncome" class="form-textbox" [readonly]="(data && data.annualIncome) && !isEditable" />
        </div>
    </div><br><br>
    <div class="title">ನೋಂದಣೆ ಮಾಡಿದ ದಿನಕ್ಕಿಂತ ಮುಂಚಿತವಾಗಿ ನೀವು ೧೫ ವರ್ಷಗಳಿಗೂ ಕಡಿಮೆ ಇಲ್ಲದಂತೆ ಕರ್ನಾಟಕ ರಾಜ್ಯದಲ್ಲಿ ವಾಸವಾಗಿರುವಿರಾ ? </div>
    <div class="title">Are you Residing in Karnataka for not les than 15 years prior to the date of Registration for Site ?</div>
    <div class="col-sm-12 col-md-12">
        <div class="row radio-row">
            <input id="isRegForSite" type="radio" [(ngModel)]="isRegForSite" value="yes" name="isRegForSite" [disabled] = "(disableResidingInsideKarnataka) && !isEditable"/><br>
            <label for="single" class="radio-text">Yes / ಹೌದು</label>
        </div>
        <div  class="row radio-row">
             <input id="isRegForSite" type="radio" [(ngModel)]="isRegForSite" value="no" name="isRegForSite" [disabled] = "(disableResidingInsideKarnataka) && !isEditable"/><br>
             <label for="joint" class="radio-text">No / ಇಲ್ಲ</label>
    </div>
    <br>
    <div class="title">ತಾವು ಅಥವಾ ತಮ್ಮ ಅವಲಂಭಿತ ಕುಟುಂಬ ಸದಸ್ಯರ ಪೈಕಿ ಬೆಂಗಳೂರು ಅಭಿವೃದ್ಧಿ ಪ್ರಾಧಿಕಾರದಿಂದ ಅಥವಾ ಬೆಂಗಳೂರು ಮೆಟ್ರೊಪಾಲಿಟಿನ್ ಪ್ರದೇಶದ ವ್ಯಾಪ್ತಿಯಲ್ಲಿ ಯಾವುದೇ ಸಹಕಾರ ಸಂಘದಿಂದ ನಿವೇಶನ ಅಥವಾ ಮನೆಯ ಹಂಚಿಕೆ ಪಡೆದಿರುವಿರಾ? (BDA Rule 10(3))  </div>
    <div class="title">Have you or your dependents in the family been allotted site or house by BDA or in the Bangalore Metropolitan area jurisdiction by any Co-operative Society? (BDA Rule 10(3)) </div>
    <div class="col-sm-12 col-md-12">
        <div class="row radio-row">
            <input id="isHouseAllotedByBda" type="radio" [(ngModel)]="isHouseAllotedByBda" value="yes" name="isHouseAllotedByBda" [disabled] = "disableHouseAllotedByBda && !isEditable"/><br>
            <label for="single" class="radio-text">Yes / ಹೌದು</label>
        </div>
        <div  class="row radio-row">
             <input id="isHouseAllotedByBda" type="radio" [(ngModel)]="isHouseAllotedByBda" value="no" name="isHouseAllotedByBda" [disabled] = "disableHouseAllotedByBda && !isEditable"/><br>
             <label for="joint" class="radio-text">No / ಇಲ್ಲ</label>
    </div>
    <br>
    <div class="title">ತಾವು ಅಥವಾ ತಮ್ಮ ಅವಲಂಭಿತ ಕುಟುಂಬ ಸದಸ್ಯರ ಪೈಕಿ ಯಾರಾದರೂ ಕರ್ನಾಟಕ ರಾಜ್ಯದ ಯಾವುದೇ ಪ್ರದೇಶದಲ್ಲಿ ಅಥವಾ ಯಾವುದೇ ನಗರಾಭಿವೃದ್ಧಿ ಅಥವಾ ಕರ್ನಾಟಕ ಗೃಹ ಮಂಡಳಿಯಿಂದ ಅಥವಾ ಬೇರೆ ಯಾವುದೇ ಸಂಸ್ಥೆಯಿಂದ ನಿವೇಶನ ಅಥವಾ ಮನೆಯ ಹಂಚಿಕೆ ಪಡೆದಿರುವಿರಾ ? (BDA Rule 10(3))   </div>
    <div class="title">Have you or your dependents in the family been allotted site or house in any region of Karnataka state by any Urban Development or Karnataka Housing Board? (BDA Rule 10(3))  </div>
    <div class="col-sm-12 col-md-12">
        <div class="row radio-row">
            <input id="isHouseAllotedByUrban" type="radio" [(ngModel)]="isHouseAllotedByUrban" value="yes" name="isHouseAllotedByUrban" [disabled] = "disableHouseAllotedByUrban && !isEditable"/><br>
            <label for="single" class="radio-text">Yes / ಹೌದು</label>
        </div>
        <div  class="row radio-row">
             <input id="isHouseAllotedByUrban" type="radio" [(ngModel)]="isHouseAllotedByUrban" value="no" name="isHouseAllotedByUrban" [disabled] = "disableHouseAllotedByUrban && !isEditable"/><br>
             <label for="joint" class="radio-text">No / ಇಲ್ಲ</label>
    </div>
        <div fxLayout="row" style="margin-top: 20px">
            <button fxFlexOffset="70" class="btn btn-next" (click)="onSubmit()">Save & Continue</button>
        </div>
    </div>
</div>
