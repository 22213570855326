<div class="col-sm-10 offset-sm-1">
  <app-top-bar selectedTab="home"></app-top-bar>
  <app-header></app-header>

  <div class="col-sm-12 root-container"> 
      <div class="container">
          <div class="row">
              <div class="col-sm-1 refresh-btn-container">
                  <button class="btn btn-primary" (click)="refreshClick()">
                      <fa-icon [icon]="faSync" [spin]="isRefreshing"></fa-icon>
                  </button>
              </div>
              <div class="col-sm-2 refresh-btn-container">
                  <button class="btn btn-primary" (click)="goBack()">
                      Back
                  </button>
              </div>

            <div class="col-sm-5">
              <div class="text">
              <p>Committee Observations - <div>{{notificationHeading}} - Summary</div>
              </div>
          </div>
      
          <div class="col-sm-4">
              <div class=" user-details">
                  <div class="value-text">
                      Welcome 
                  </div>
                  <div class="value-text">
                       {{user['firstName']}} !
                  </div>
              </div>
              </div>
              <div class="col-sm-3 offset-sm-9" style="margin-top: 21px; margin-left: 1%;" >
                  <div fxLayout="row" fxLayoutAlign="space-around">
                      <input class="form-control border-end-0 border rounded-pill" style="font-size: 14px !important;" type="text"  [(ngModel)]="applicationNo" placeholder="Search" id="applicationNo">
                      <span class="input-group-append"><br>&nbsp;
                       <button mat-button class="btn btn-primary " (click)="getApplicantData()">Search</button> 
                       <button mat-button *ngIf="applicationNo" matSuffix mat-icon-button aria-label="Clear" (click)="onClear()">
                          <mat-icon>close</mat-icon>
                        </button>
                      </span>
                     
                      </div>
              </div>

              <div class="col-sm-2 offset-sm-4 village-details">
                  <mat-form-field appearance="fill">
                      <mat-label>Village</mat-label>
                      <mat-select id="village" name="villageId" [(ngModel)]="villageId" (selectionChange)="onVillageChange()">
                        <mat-option *ngFor="let village of villages" [value]="village.id">
                          {{village.id}} {{village.name}}
                        </mat-option>
                      </mat-select>
          
                    </mat-form-field>
                    <div class="p1">
                    Total: {{totalSize}}
                  </div>
              </div>
              <div class="col-sm-1 download-xls">
                <img class="square-icon" src="../../../assets/download_xlsx_icon.png" (click)="downloadAllAsExcelsheet()" />
            </div>
              <div class="col-sm-12">
                  <table mat-table [dataSource]="dataSource">
                  
                      <!-- SI No -->
                      <ng-container matColumnDef="siNo">
                             <th mat-header-cell *matHeaderCellDef> SI No. </th>
                             <td mat-cell *matCellDef="let element ;let i =index;" class="column-container">{{i+1}}
                         </ng-container>

                      <!-- Application No Column -->
                     <ng-container matColumnDef="applicationId">
                      <th mat-header-cell *matHeaderCellDef> Application No </th>
                      <td mat-cell *matCellDef="let element" (click)="onPreviewClick(element.id)">
                        <a target="_blank" class="preview-link">{{element.applicationNo}}</a> <span class="vertical-divider"></span>
                  </ng-container>
             
                     <!-- Applicant Name Column -->
                     <ng-container matColumnDef="applicantName">
                         <th mat-header-cell *matHeaderCellDef> Applicant Name </th>
                         <td mat-cell *matCellDef="let element">  {{element.applicantName}} </td>
                     </ng-container>
                     
                     <!--  Mobile No Column -->
                     <ng-container matColumnDef="mobileNumber">
                         <th mat-header-cell *matHeaderCellDef> Mobile No </th>
                         <td mat-cell *matCellDef="let element">   {{element.mobileNumber}}  </td>
                     </ng-container>
             
                     <!-- Comment Column -->
                     <ng-container matColumnDef="comment">
                         <th mat-header-cell *matHeaderCellDef>Operative Portions of the Report </th>
                         <td mat-cell *matCellDef="let element">  {{element.comment}}   </td>
                     </ng-container>

                      <!-- Status Column -->
                      <ng-container matColumnDef="status">
                             <th mat-header-cell *matHeaderCellDef>Status </th>
                             <td mat-cell *matCellDef="let element">{{element.status ? element.status : 'Pre Adjudication'}}</td>
                         </ng-container>

                     <!-- Village Name Column -->
                     <ng-container matColumnDef="villageName">
                         <th mat-header-cell *matHeaderCellDef> Village Name </th>
                         <td mat-cell *matCellDef="let element">  {{element.villageName}}  </td>
                     </ng-container>

                      <!-- Updated Column -->
                      <ng-container matColumnDef="updatedBy">
                        <th mat-header-cell *matHeaderCellDef> Updated By </th>
                        <td mat-cell *matCellDef="let element">  {{element.updatedBy}}  </td>
                    </ng-container> 

                     <!-- Updated Column -->
                     <ng-container matColumnDef="updatedAt">
                        <th mat-header-cell *matHeaderCellDef> Updated At </th>
                        <td mat-cell *matCellDef="let element">  {{formattDate(element.historyUpdatedAt)}}  </td>
                    </ng-container>               

                    <!-- Alternate site value Column -->
                     <ng-container matColumnDef="alternateSite">
                        <th mat-header-cell *matHeaderCellDef> Recom for alternate site </th>
                        <td mat-cell *matCellDef="let element" [ngStyle]="{ 'color': element.alternateSiteObservationData.value === 'yes' ? 'green' : 'red' }">
                              {{element.alternateSiteObservationData ? element.alternateSiteObservationData.value : ''}}  </td>
                    </ng-container>        
             
                    <!-- Observation Document Column -->
                    <ng-container matColumnDef="action">
                      <th mat-header-cell *matHeaderCellDef> Document  </th>
                      <td mat-cell *matCellDef="let element" >
                        <ng-container *ngIf="element.observationDocumentURL">
                          <a *ngIf="element.observationDocumentURL"  target="_blank" href="{{element.observationDocumentURL}}" class="preview-link">View Document</a> <span class="vertical-divider"></span>
                        </ng-container>
                        <!-- If Observation Document not uploaded -->
                        <ng-container *ngIf="!element.observationDocumentURL">
                            No document
                        </ng-container>
                    </ng-container>
                     
                     <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                     <tr mat-row *matRowDef="let row; columns: displayedColumns;" ></tr>
                 </table>
                 <div class="no-records-text" *ngIf="!dataSource.data || dataSource.data.length <= 0">No Records Found!</div>
                 <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true" [length]="totalSize"
                     [pageIndex]="currentPage" (page)="getApplicantPaginationData($event)">
                 </mat-paginator>
              </div>
          </div>
          <app-footer class="col-md-10 offset-sm-3" ></app-footer>