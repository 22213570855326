<div class="container">
    <div>
        <app-top-bar selectedTab="home"></app-top-bar>
        <app-header></app-header>
    </div>
    <br>
   
    <div class="tool-bar">
        <div class="refresh-btn">
        <button mat-button class="btn btn-primary"(click)="clickBack()">Back</button>
    </div>
     
        <div class="col-sm-3 offset-sm-1 user-details">
            <div class="value-text">
                Welcome  <br>
            {{user['firstName']}} !
            </div>
            </div>
        </div>

        
    <div>
        <section>
            <div fxLayout="row" fxLayoutAlign="space-between">
            <div class="row justify-content-between">
            <div class="col-4 title">
                <!-- <div class="p1">
                <mat-form-field appearance="fill">
                    <mat-label>Status</mat-label>
                    <mat-select id="status" name="selectedStatus" [(ngModel)]="selectedStatus">
                      <mat-option *ngFor="let status of statusList" [value]="status" (click)="onStatusSelected()">
                        {{status.status}}
                      </mat-option>
                    </mat-select>
                  
                  </mat-form-field>
                  <div class="p1">
                  Total: {{totalApplicantCount}}
                </div>
            </div> -->
            </div>
            </div>
        <div class="col-sm-3 offset-sm-1">
            <div fxLayout="row" fxLayoutAlign="space-around">
            <input class="form-control border-end-0 border rounded-pill" style="font-size: 14px !important;"type="text" [(ngModel)]="applicationNo" placeholder="Search" id="applicationNo">
            <span class="input-group-append"><br>&nbsp;
             <button mat-button class="btn btn-primary " (click)="searchApplicant()">Search</button> 
             <button mat-button *ngIf="applicationNo" matSuffix mat-icon-button aria-label="Clear" (click)="onClear()">
                <mat-icon>close</mat-icon>
              </button>
            </span>
            </div>
        </div>
    </div>
    <!-- <div class="col-sm-12" *ngIf="selectedStatus && selectedStatus.statusCode == 'out_of_bounds'">
        <div class="row application-form-row ">
            <mat-form-field appearance="fill">
                <mat-label>Out of Bounds Status Option</mat-label>
                <mat-select id="status" name="selectedStatusOption" [(ngModel)]="selectedStatusOption">
                    <mat-option *ngFor="let statusOption of statusOptions" [value]="statusOption" (click)="onStatusOptionSelected()">
                    {{statusOption.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
             
        </div>
        <div class="p1">
            Total: {{totalCount}}
         </div> 
    </div> -->
                <div class="col-sm-11 title">
                    ACTIVITY LOG
                </div>
              
            
           
            <!-- data table -->
            <mat-card>
                <table mat-table [dataSource]="dataSource">
                    <ng-container matColumnDef="sn">
                        <th mat-header-cell *matHeaderCellDef> Serial No. </th>
                        <td mat-cell *matCellDef="let element ;let i =index;" class="column-container">{{i+1}}
                          </ng-container>
                    <!-- Position Column -->
                    <ng-container matColumnDef="applicationNo">
                        <th mat-header-cell *matHeaderCellDef> Application No. </th>
                        <td mat-cell *matCellDef="let element">
                            <a (click)="onPreviewClick(element.id)" class="preview-link">{{element.applicationNo}} </a> 
                        </td>
                    </ng-container>
                    
                    <!-- Name Column -->
                    <ng-container matColumnDef="applicantName">
                        <th mat-header-cell *matHeaderCellDef> Name </th>
                        <td mat-cell *matCellDef="let element"> {{element.applicantName}} </td>
                    </ng-container>

                      <!-- Channel Column -->
                      <ng-container matColumnDef="channel">
                        <th mat-header-cell *matHeaderCellDef> Channel </th>
                        <td mat-cell *matCellDef="let element"> 
                            <div *ngIf = "element.applicantType == 'helpdesk_user'">
                                {{'HD'}} 
                           </div>
                           <div *ngIf = "element.applicantType == 'online_application_user'">
                               {{'Online'}} 
                          </div>
                       </td>
                    </ng-container>
                    
                    <!-- Telephone Column -->
                    <ng-container matColumnDef="observationDocumentURL">
                        <th mat-header-cell *matHeaderCellDef class ="data-header"> Actions </th>
                        <td mat-cell *matCellDef="let element" class="column-container">
                            <a *ngIf="element.observationDocumentURL" target="_blank" href="{{element.observationDocumentURL}}" class="preview-link pdf-btn">
                                <mat-icon aria-label="picture_as_pdf">picture_as_pdf</mat-icon>
                            </a>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" ></tr>
                </table>
                <div class="no-records-text" *ngIf="!dataSource.data || dataSource.data.length <= 0">No Records Found!</div>
                <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true" 
                [pageIndex]="currentPage" [length]="totalSize" (page)="getApplicantPaginatorData($event)">
            </mat-paginator>
            </mat-card>
        </section>
    </div>
 <app-footer></app-footer>
 </div>


