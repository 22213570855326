import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ApplicationService } from 'src/services/application.service';
import { Subscription } from 'rxjs';
import { ConfirmationModalComponent } from 'src/app/confirmation-modal/confirmation-modal.component';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { JccnoEncodeDecodeService } from 'src/services/jccno-encode-decode/jccno-encode-decode.service';
import { ActivatedRoute } from '@angular/router';
import { faTrash} from '@fortawesome/free-solid-svg-icons';
import { WebcamImage } from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';
import { ValueConverter } from '@angular/compiler/src/render3/view/template';

@Component({
  selector: 'app-personal-info',
  templateUrl: './personal-info.component.html',
  styleUrls: ['./personal-info.component.scss']
})
export class PersonalInfoComponent implements OnInit {

  public data : any;

  public isEditable: any;

  public menus: any = [
    {
      name:'Personal Info', 
      type: 'link',
      active: true,
      code: 'personal_info'
    }
  ];

  public applicantName : string; 

  public mobileNumber : number;

  public email : string;

  public surveyNumber : string;

  public aadharNumber : number;

  public villages : any[];

  public selectedVillage;

  public applicantAddress : string;
  
  public selectedMenu: any = {};

  private applicantId: any;

  public maxDate = new Date().getTime();

  public DateOfBirth: Date;

  public gender : string;

  public isRegForSite;

  public married : any;

  public cameraChoosen = false;

 public selectedFile;

 public selectedFileName;

 public profileImg : any ;

 faTrash = faTrash;

  @Output() newApplicantCallback = new EventEmitter<any>();

  @Output() nextScreenCallback = new EventEmitter<any>();
  age: any;
  occupation: any;
  panNumber: any;
  annualIncome: any;
  spousesName: any;
  childrenNO: any;
  occupations: any;
  dateOfBirth: any;
  dateOfBirths: any;
  occupation1: any;
  dateOfBirth1: any;
  public show=true;
  isGender: string;
  applicantGender: any;
  applicantAge: any;
  applicantOccupation: any;
  spouseOccupation: any;
  panNo: any;
  spouseName: any;
  noOfChildren: any;
  applicantDob: any;
  spouseDob: any;
  aadharNo: any;
  dependentSite:any;
  housingBoard:any;
  
  public disableMartialStatus: boolean = false;
  public disableResidingInsideKarnataka: boolean = false;
  public disableGender: boolean = false;
  public disableHouseAllotedByBda: boolean = false;
  public disableHouseAllotedByUrban: boolean = false;
  isDependentSite: string;
  isHousingBoard: string;
  isHouseAllotedByBda: string;
  isHouseAllotedByUrban: string;
  public maritialStatus: string;
  
  
  
  constructor(private applicationService: ApplicationService,
     public dialog: MatDialog,
     private _snackBar: MatSnackBar, 
     private route: ActivatedRoute, 
     private jccEncodeDecoder: JccnoEncodeDecodeService) { }
  
  ngOnInit(): void {
    this.selectedMenu = this.menus[0];
    this.getAllVillages();
    let encodedNo = this.route.snapshot.queryParams["applicationNo"];
    if(encodedNo) {
      let applicantNo = this.jccEncodeDecoder.decode(this.route.snapshot.queryParams["applicationNo"]);
      this.fetchApplicationData(applicantNo);
    } else {
      this.applicantId = window.localStorage.getItem("applicantId");
      this.fetchApplicantById(this.applicantId);
     
    }
  }
 
 

  private fetchApplicantById = (applicantId: any) => {
    if(!applicantId) {
      return;
    }
    this.applicationService.fetchApplicantById(applicantId).subscribe(res =>{
      this.updateUI(res);
    })
  }

  public residingInsideKarnataka: boolean = false;

  private updateUI(res: any): void {
    this.applicantId = res.id;
    this.setEditable(res);
    this.data = res;
    if(res) {
      if(res.regForSite != null) {
        this.disableResidingInsideKarnataka = true;
      }
      if(res.maritalStatus != null) {
        res.maritalStatus = res.maritalStatus.toLowerCase();
        this.disableMartialStatus = true;
      }
      if(res.gender != null) {
        res.gender = res.gender.toLowerCase();
        this.disableGender = true;
      }
      if(res.isHouseAllotedByBda != null) {
        this.disableHouseAllotedByBda = true;
      }
      if(res.isHouseAllotedByUrban != null) {
        this.disableHouseAllotedByUrban = true;
      }
    }
    this.setValue(res);
    this.newApplicantCallback.emit(res);
  }
  uploadPropertyPicture(){
    if((!this.selectedFile && !this.selectedFileName) || (this.data && this.data.propertyImage)){
      return;
    }
    console.log('selected file ',this.selectedFile)
    const formData = new FormData();
    formData.append('profile_pic', this.selectedFile, this.selectedFileName);
     this.applicationService.uploadPropertyPicture(this.data.id, formData).subscribe(res =>{
        this.data=res;
        this.profileImg = this.data.propertyImage;
     })
  }
  
  getAllVillages(){
    this.applicationService.fetchAllVillages().subscribe(res =>{
      this.villages = res;
    }, err=> {
      console.log("personal details component getVillages() err", err);
    }, () => {
        console.log("personal details component getVillages() completed");
    })
  }

  fetchApplicationData = (applicantNo: any) => {
    this.applicationService.fetchApplicantByApplicationNo(applicantNo).subscribe(res=>{
        console.log("basic personal details", res);
        this.updateUI(res);
    })
   }

  reset(){
   // this.applicantGender = null;
   // this.applicantAge = null;
    this.applicantOccupation = null;
    this.panNo = null;
    this.annualIncome = null;
   // this.spouseName = null;
    this.spouseOccupation=null;
   // this.applicantDob = null;
    //this.spouseDob = null;
   // this.isRegForSite = null;

  }

  validateAndConstructPayload = () => {
      if(!this.applicantDob) {
        throw new Error('Select applicant Date of Birth');
      }
      if(!this.applicantGender){
        throw new Error('gender field must not be empty');
      }
      if(this.maritialStatus == null) {
        throw new Error('Martial Status Should not be empty');
      }
      if(!this.aadharNo) {
        throw new Error('Aadhar Number Should not be empty');
      }
      let aadhar = this.aadharNo.toString();
      if(aadhar.length != 12) {
        throw new Error('Enter 12 digit aadhar number');
      }
      let payload = {};
      payload['gender'] = this.applicantGender;
      payload['applicantAge'] = this.applicantAge;
      payload['applicantOccupation'] = this.applicantOccupation;
      payload['aadharNo'] = this.aadharNo;
      payload['panNo'] = this.panNo;
      payload['annualIncome'] = this.annualIncome;
      payload['applicantDob'] = this.applicantDob;
      payload['maritalStatus'] = this.maritialStatus;
      payload['propertyImage'] = this.profileImg;
      payload['regForSite'] = this.isRegForSite == 'yes' ? true : this.isRegForSite == 'no'? false : null;
      payload['isHouseAllotedByBda'] = this.isHouseAllotedByBda == 'yes' ? true : this.isHouseAllotedByBda == 'no'? false : null;
      payload['isHouseAllotedByUrban'] = this.isHouseAllotedByUrban == 'yes' ? true : this.isHouseAllotedByUrban == 'no'? false : null;
      return payload;
  }

  setValue(data: any): void{
    console.log('data ', data)
    this.applicantGender = data.gender;
    this.applicantAge = data.applicantAge;
    this.applicantOccupation = data.applicantOccupation;
    this.spouseOccupation= data.spouseOccupation;
    this.panNo = data.panNo;
    this.annualIncome = data.annualIncome;
    this.applicantDob = data.applicantDob;
    this.maritialStatus = data.maritalStatus;
    this.aadharNo = data.aadharNo;
    if(data.regForSite != null) {
      this.isRegForSite = data.regForSite ? "yes" : 'no';
      this.profileImg = this.data.propertyImage;
    }
    if(data.isHouseAllotedByBda != null) {
      this.isHouseAllotedByBda = data.isHouseAllotedByBda ? "yes" : 'no';
    }
    if(data.isHouseAllotedByUrban != null) {
      this.isHouseAllotedByUrban = data.isHouseAllotedByUrban ? "yes" : 'no';
    }
  }

  public onItemClicked = (event: any) => {
    this.selectedMenu = event;
  }

  onSubmit = () => {
    this.openDialog();
  }

  openSnackbar(message: string, action?: string){
    if(!action) {
      action = "Dismiss";
    }
    this._snackBar.open(message, action);
  }

  openDialog = () => {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '160px';
    dialogConfig.width = '450px';
    dialogConfig.data = "Are you sure want to submit this record ?"
    const dialogRef = this.dialog.open(ConfirmationModalComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(res =>{
        if(res){
          if(this.applicantId) {
            this.updateApplicantBasicDetailsApiCall(this.applicantId);
            return;
          }
          this.uploadPropertyPicture();
          this.saveApplicantBasicDetailsApiCall();
        }
    })
  }

  saveApplicantBasicDetailsApiCall = () => {
    try{
      let payload  = this.validateAndConstructPayload();
      this.applicationService.saveApplication(payload).subscribe(res =>{
        window.localStorage.setItem("applicantId", res.id);
        this.openSnackbar("Successfully Saved", "Dismiss");
        this.updateUI(res);
      }, err=>{
          console.log(err);
          this.openSnackbar(err?.message, "Dismiss");
      })
    } catch(e){
      this.openSnackbar(e, "Dismiss");
    }
  }

  private setEditable = (data: any): void => {
    if(data['oneTimeEdit'] != null) {
      this.isEditable = data['oneTimeEdit'];
    }
  }

  updateApplicantBasicDetailsApiCall = (applicantId: any) => {
    try{
      let payload  = this.validateAndConstructPayload();
      this.applicationService.saveSiteDetails(applicantId, payload).subscribe(res =>{
        this.openSnackbar("Successfully Updated", "Dismiss");
        this.updateUI(res);
      }, err => {
          console.log(err);
          this.openSnackbar(err?.message, "Dismiss");
      })
    } catch(e){
      this.openSnackbar(e, "Dismiss");
    }
  }
  handleImage(webcamImage: WebcamImage): void { 
    this.cameraChoosen = false;
   this.profileImg = webcamImage.imageAsDataUrl
  //  this.selectedFile =  webcamImage.imageAsDataUrl;
  console.log(this.profileImg);
  
 this.selectedFileName = 'image.jpg';
 const base64 = webcamImage.imageAsBase64;
const imageName = 'name.png';
const imageBlob = this.dataURItoBlob(base64);
this.selectedFile = new File([imageBlob], imageName, { type: 'image/png' });
  } 
  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/png' });    
    return blob;
 }
  private trigger: Subject<void> = new Subject<void>(); 
  triggerSnapshot(): void { 
   this.trigger.next(); 
  } 

  chooseCamera(){
    this.cameraChoosen = true;
  }
  
  removePicture(){
    this.profileImg = null;
    this.selectedFile = null;
    this.selectedFileName = null;
  }
  onGalleryImageChoosen($event){
    console.log('event ', $event.target.files[0])
    this.selectedFile =  $event.target.files[0];
    this.selectedFileName = this.selectedFile.name;
    
    let currentComponent = this;
    var reader = new FileReader();
    reader.onload = function (e) {
      console.log('file ',e.target.result);
      currentComponent.profileImg = e.target.result;
    };
    reader.readAsDataURL($event.target.files[0]);
   }
  fetchFromGallery(){
    document.getElementById("image-gallery").click();
  }

  public get triggerObservable(): Observable<void> { 
    return this.trigger.asObservable(); 
   } 

}
