import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { forkJoin } from 'rxjs';
import { ApiService } from 'src/services/api.service';
import { ApplicationService } from 'src/services/application.service';
import { StrorageService } from 'src/services/strorage.service';

@Component({
  selector: 'app-leadership-users',
  templateUrl: './leadership-users.component.html',
  styleUrls: ['./leadership-users.component.scss']
})
export class LeadershipUsersComponent implements OnInit {

  public pageSize = 5;
  public currentPage = 0;
  public totalSize: number= 0;
  public faSync = faSync;
  public isRefreshing = false;
  public displayedColumns: string[] = ['sn','applicationNo', 'applicantName','channel'];
  
  public dataSource = new MatTableDataSource<any>();
  selectedDateFilter:any;
  user: any;
  village: any;
  villages : any[];
  villageId: any;
  applicationNo = null;
  selectedVillages: number;
  public totalApplicantCount: any = 0;
  show: boolean;
  applicantServiceApiCallObserver: any;
  private piUserLastActivity: any;
  statusCode: any;
  application_id: any;

  constructor(private applicationService: ApplicationService,
    private cdr:ChangeDetectorRef,
    private router: Router,private storageService:StrorageService,private route: ActivatedRoute) { 
    }

    ngOnInit(): void {
      this.user=this.storageService.getUser();
      this.fetchLeaderShipUsersApplicants();
      this.fetchVillages();
    
    }
    fetchVillages(){
      this.applicantServiceApiCallObserver = this.applicationService.fetchAllVillages().subscribe(res=>{
        this.villages = res;
        this.villages.unshift({
          "id":"",
          "name":"All"
         });
         this.restoreLastUserActivity();
        })
      }
  

    restoreLastUserActivity(): void {
        if(!this.piUserLastActivity) {
          return;
        }
        let lastActivity = this.piUserLastActivity;
        if(lastActivity.villageId) {
          this.villageId = lastActivity.villageId;
          this.onVillageChange();
        }
        
      }
    public getApplicantPaginatorData(event: PageEvent) {
      this.currentPage = event.pageIndex + 1;
      this.pageSize = event.pageSize;
      this.fetchLeaderShipUsersApplicants();
    }
    onVillageChange(): void{
      this.show=false;
      this.fetchLeaderShipUsersApplicants();
    }

    onClear() {
      this.applicationNo = null;
      this.fetchLeaderShipUsersApplicants();
    }
    
    
    public showActivityLog = () => {
      this.router.navigate(['/leadership-users-activity-log']);
    }
  
    public fetchLeaderShipUsersApplicants = () => {
      this.applicationService.getLeadershipApplicants(this.currentPage,this.pageSize, 
       this.applicationNo, this.villageId).subscribe(res => {
        this.refreshDataTable(res);
        console.log(res);
        
      })
    }
    
    searchApplicant(){
      this.fetchLeaderShipUsersApplicants();
    }
  
    private refreshDataTable = (res: any) => {
      this.dataSource.data = res.data;
      this.totalSize = res.totalRecord;
      this.currentPage = (res.currentPage - 1);
      this.dataSource._updateChangeSubscription();
      if (!this.cdr['destroyed']) {
        this.cdr.detectChanges();
      }
    }
  
    navigateToLeadershipHome(){
      this.router.navigate(['/applicant-list']);
  
    }
    onPreviewClick(id: any){
      this.router.navigate(['/leadership-users-workbench'],  {queryParams:{application_id:id}});
     }

     refreshClick(){
      let currentComponent = this;
      this.isRefreshing = true;
      setTimeout(()=>{
        currentComponent.isRefreshing = false;
      }, 3000)
      this.fetchLeaderShipUsersApplicants();
    }
  }
  
