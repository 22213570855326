<div fxFlex="100%">
    <div fxFlex="25" class="side-menu-container">
        <app-side-menu [menus]="menus" (onMenuClick)="onItemClicked($event)"></app-side-menu>
    </div>

    <div fxFlex="80" style="padding-left: 55px">
        <app-court-details [hidden]="selectedMenu['code'] != 'court_details'"></app-court-details>
        <div [hidden]="selectedMenu['code'] != 'property_details'">
                <div class="title" style="font-size: medium;">ಡಾ|| ಕೆ. ಶಿವರಾಮ ಕಾರಂತ್ ಬಡಾವಣೆಯ ಭೂಸ್ವಾಧೀನ ಅಧಿಸೂಚನೆಯಲ್ಲಿ :-
                        In the Land Acquisition Notification of Dr. K. Shivarama Karanth Layout? 
                        </div>
                        <br>
                        <div fxLayout="row" fxLayoutAlign="space-between">
                            <div style="width: 50%"  class="row application-form-row">
                                <label class="form-label"> 1. ತಮ್ಮ ರೆವಿನ್ಯೂ ನಿವೇಶನವಿರುವ ಗ್ರಾಮದ ಹೆಸರು:
                                    Village where your revenue site is located :</label>
                            </div>
                            <div style="width: 50%"  class="row application-form-row"> 
                                <input type="text" [(ngModel)]="selectedVillage" class="form-textbox" [readonly]="true" />
                            </div>
                        </div>
                        <br>
                        <div fxLayout="row" fxLayoutAlign="space-between">
                            <div style="width: 50%"  class="row application-form-row">
                                <label class="form-label"> 2. ಸರ್ವೇ ನಂ./Survey:</label>
                            </div>
                            <div style="width: 50%"  class="row application-form-row"> 
                                <input type="text" [(ngModel)]="surveyNo" class="form-textbox" [readonly]="true" />
                            </div>
                            
                        </div>
                        <div fxLayout="row" fxLayoutAlign="space-between">

                        <div style="width: 50%"  class="row application-form-row">
                            <label class="form-label"> 3. ಸರ್ವೇ ನಂ./ Survey No ಹಿಸ್ಸಾ/ Hissa :</label>
                        </div>
                        <div style="width: 50%"  class="row application-form-row"> 
                            <input type="text" [(ngModel)]="surveyNoHissa" class="form-textbox" [readonly]="true" />
                        </div>
                        </div>
                        <br>
                        <div fxLayout="row" fxLayoutAlign="space-between">
                            <div style="width: 50%"  class="row application-form-row">
                                <label class="form-label">  4. ನಿವೇಶನ ಸಂಖ್ಯೆ/Site No. </label>
                            </div>
                            <div style="width: 50%"  class="row application-form-row"> 
                                <input type="text" [(ngModel)]="siteNo" class="form-textbox" [readonly]="true" />
                            </div>
                        </div>
                        <br>
                        <div fxLayout="row" fxLayoutAlign="space-between">
                            <div style="width: 50%"  class="row application-form-row">
                                <label class="form-label">  5. ನಿವೇಶನದ ವಿಸ್ತೀರ್ಣ /Site Dimension </label>
                            </div>
                            <div style="width: 50%"  class="row application-form-row"> 
                                <input type="text" [(ngModel)]="propertyDetailsSiteDimension" class="form-textbox" [readonly]="(data && data.propertyDetailsSiteDimension) && !isEditable" />
                            </div>
                        </div>
                        <br>
                        <div fxLayout="row" fxLayoutAlign="space-between">
                            <div style="width: 50%"  class="row application-form-row">
                                <label class="form-label">  6. Date of Registration of the Sale Deed </label>
                            </div>
                            <div style="width: 50%"  class="row application-form-row"> 
                                <input type="date" max="{{ maxDate |date:'yyyy-MM-dd'}}"  onkeydown="return false" [(ngModel)]="saledeedRegDate" class="form-textbox" [readonly] = "(data && data.saledeedRegDate) && !isEditable"/>
                            </div>
                        </div>
                        <br>
                        <br>
                
                <div class="title">6. ರೆವೆನ್ಯೂ ನಿವೇಶನ ಒಳಗೊಂಡಿರುವ ಗ್ರಾಮ ಪಂಚಾಯಿತಿಯಲ್ಲಿ ಖಾತೆ ಮಾಡಿಸಲಾಗಿದೆಯೇ? Does the Revenue site has a Khata issued by the concerned Panchayat? </div>
                   <div class="col-sm-12 col-md-12">
                       <br>
                      <div class="row radio-row">
                        <input id="isKhataIssuedByPanchayat" type="radio" [(ngModel)]="isKhataIssuedByPanchayat" name="isKhataIssuedByPanchayat" [value]="true" [disabled] = "(data && data.isKhataIssuedByPanchayat != null) && !isEditable"/><br>
                        <label for="single" class="radio-text">ಹೌದು / Yes</label>
                      </div>
                    <div  class="row radio-row">
                         <input id="isKhataIssuedByPanchayat" type="radio" [(ngModel)]="isKhataIssuedByPanchayat"  name="isKhataIssuedByPanchayat" [value]="false" [disabled] = "(data && data.isKhataIssuedByPanchayat != null) && !isEditable"/><br>
                         <label for="joint" class="radio-text">ಇಲ್ಲ / No</label>
                   </div>
                     </div>
                     <div *ngIf ="isKhataIssuedByPanchayat">
                     <div fxLayout="row" fxLayoutAlign="space-between">
                        <div style="width: 50%"  class="row application-form-row">
                            <label class="form-label"> ಹೌದಾದಲ್ಲಿ, ಗ್ರಾಮದ ಹೆಸರು:If Yes, Panchayat Name :</label>
                        </div>
                        <div style="width: 50%"  class="row application-form-row"> 
                                    <label class="form-label">Choose Panchayat  : </label>
                            <select class="form-textbox" [(ngModel)]="selectedPanchayat" [disabled]="(data && data.isKhataIssuedByPanchayat != null) && !isEditable">
                                <option disabled="false">Select Panchayat</option>
                             <option *ngFor = "let panchayat of panchayats" [value]="panchayat.id">{{panchayat.id}}. {{panchayat.name}}</option>
                            </select>
                        </div>
                    </div>
        </div>
        <div fxLayout="row" style="margin-top: 20px">
            <button fxFlexOffset="70" class="btn btn-next" (click)="onSubmit()">Save & Continue</button>
        </div>
    </div>
    </div>
</div>
