import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { StrorageService } from './strorage.service';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {


  constructor(private apiService: ApiService, private storageService: StrorageService) { }

  saveApplication(payload: any): Observable<any> {
    return this.apiService.post('/v1/applicants', payload);
  }

     getApplicants(filters?: any): Observable<any>{
return this.apiService.post('/v1/get_applicants', filters);
 }

 getApplicantsList(): Observable<any>{
  return this.apiService.get('/v1/applicants');
   }
getActivityLogs(page?: any, size?: any):Observable<any>{
  if(!page) {
    page = 0;
 }
 if(!size) {
    size = 10;
 }
 let envelop = {};
 envelop['page'] = page;
 envelop['size'] = size;
   return this.apiService.post('/v1/scrutiny/activity_logs',envelop);
}

getScrutinyUserApplicants(filterType?: any,approvedFilter?:any, page?: any, size?: any,applicationNo?:any): Observable<any> {
  let user = this.storageService.getUser();
  if(!user) {
    throw new Error("User not logged in");
  }
  let villageId = user['villageDTO'].id;
   if(!page) {
      page = 0;
   }
   if(!size) {
      size = 10;
   }
   let url ="/v1/scrutiny_user/{villageId}/applicants"+page+"&size="+size;
   if(applicationNo) {
    url = url + "&applicationNo="+applicationNo;
  }
  if(filterType){
    url=url +"&filterType="+filterType;
  }
  if(approvedFilter){
    url=url +"&approvedFilter="+approvedFilter;
  }
  console.log(url);
  return this.apiService.get(url);
}
//  getScrutinyUserApplicants(filters?: any, page?: any, size?: any): Observable<any> {
//    if(!page) {
//       page = 0;
//    }
//    if(!size) {
//       size = 10;
//    }
//    let envelop = {};
//    envelop['page'] = page;
//    envelop['size'] = size;
//    if(filters) {
//     envelop['filters'] = filters;
//    }
//   return this.apiService.post("/v1/scrutiny_user/applicants", envelop);
//}

getScrutinyUserDashboardFilterCount(villageId: any): Observable<any> {
 return this.apiService.get("/v1/scrutiny_user/"+villageId+"/record_count");
}
getScrutinyApplicants(page?: any, size?: any,applicationNo?:any,villageId?: any): Observable<any> {
  let user = this.storageService.getUser();
  if(!user) {
    throw new Error("User not logged in");
  }
  if(!page) {
     page = 0
  }
  page = page + 1;
  if(!size) {
     size = 10;
  }
  let url="/v1/scrutiny_user/applicants?page="+page+'&'+'pageSize='+size;

  if(applicationNo) {
    url = url + "&applicationNo="+applicationNo;
  }
  
  if(villageId) {
    url = url + "&villageId="+villageId;
  }
  return this.apiService.get(url);
 }
saveFieldVisit(id:any,payload:any):Observable<any>{
  return this.apiService.post("/v1/applicants/"+id+"/field_visit",payload);
}

//Field Visit User API
getFieldUserApplicants(page?: any, size?: any): Observable<any> {
  let user = this.storageService.getUser();
  if(!user) {
    throw new Error("User not logged in");
  }
  let villageId = user['villageDTO'].id;
  if(!page) {
     page = 0;
  }
  if(!size) {
     size = 10;
  }
  let envelop = {};
  envelop['page'] = page;
  envelop['size'] = size;
  return this.apiService.get("/v1/field_visit_user/villages/"+villageId+"/applicants?page="+page+"&size="+size);
}

getFieldVisitStatus(){
  return this.apiService.get("/v1/field_visit_user/status");
}

getLoginSettings(){
  return this.apiService.get("/v1/login_settings");
}


getFieldVisitActivityLog(page?: any, size?: any):Observable<any>{
  if(!page) {
    page = 0;
 }
 if(!size) {
    size = 10;
 }
 return this.apiService.get('/v1/field_visit_user/activity_logs?page='+page+'&'+'size='+size);
}
getFieldVisitActivityLogDetails(id:any){
  return this.apiService.get('/v1/field_visit/applicants/'+id+'/activity_log_details');
}
// field visit user api end
  fetchAllVillages(): Observable<any []> {
    return this.apiService.get('/v1/villages');
  }
 fetchSurveyNoByVillageId(id): Observable<any []> {
   return this.apiService.get('/v1/villages/'+id+'/survey_nos');
 }
  fetchAllVillagesByPanchayat(id): Observable<any []> {
    return this.apiService.get('/v1/villages?panchayat='+id);
  }

  fetchAllApplicantByVillage(id): Observable<any []> {
    return this.apiService.get('/v1/applicants?village='+id);
  }
  fetchAllApplicant(): Observable<any []> {
    return this.apiService.get('/v1/applicantsCount');
  }
  fetchAllApplicantByNotificationType(notificationType: any): Observable<any []> {
    return this.apiService.get('/v1/applicantsCountByNotificationType/'+notificationType);
  }
  fetchApplicantCountByVillage(id): Observable<any []> {
    return this.apiService.get('/v1/applicantsCount?village='+id);
  }
  fetchApplicantCountByVillageAndNotificationType(notificationType: any,id): Observable<any []> {
    return this.apiService.get('/v1/applicantsCountByNotificationType/'+notificationType+'?village='+id);
  }
  fetchApplicantCountByPanchayat(id): Observable<any []> {
    return this.apiService.get('/v1/applicantsCount?panchayat='+id);
  }
  fetchApplicantCountByPanchayatAndNotificationType(notificationType: any,id): Observable<any []> {
    return this.apiService.get('/v1/applicantsCountByNotificationType/'+notificationType+'?panchayat='+id);
  }
  fetchApplicantByApplicationNo(applicationNo): Observable<any> {
    return this.apiService.get('/v1/application?applicationNo='+applicationNo);
  }
  saveSiteDetailsWithOneTimeEdit(id:any,payload:any): Observable<any>{
    return this.apiService.put('/v1/applicants/'+id+'/site_details/one_time_edit',payload)
  }
  saveSiteApprovalWithOneTimeEdit(id:any,payload:any): Observable<any>{
    return this.apiService.put('/v1/applicants/'+id+'/site_approval/one_time_edit',payload)
  }
  saveBuildingDetailsWithOneTimeEdit(id:any,payload:any): Observable<any>{
    return this.apiService.put('/v1/applicants/'+id+'building_details/one_time_edit',payload)
  }

  fetchApplicationNo(notificationType?: string): Observable<any []>{
    if(notificationType){
      return this.apiService.get('/v1/applicationNo?notificationType='+notificationType);
    }
    return this.apiService.get('/v1/applicationNo');
  }
  fetchApplicationNoByNotificationType(searchKey?: string): Observable<any []>{
    if(searchKey){
      return this.apiService.get('/v1/applicationNoByNotificationType/?searchQuery='+searchKey);
    }
    return this.apiService.get('/v1/applicationNoByNotificationType');
  }
  fetchApplicants():Observable<any []>{
    return this.apiService.get('/v1/applicationNo');
  }
  saveScrutinyReferField(id:any) : Observable<any>{
    return this.apiService.put('/v1/applicants/'+id+'/scrutinyFieldRefer');
  }
  saveScrutinyComment1(id:any,payload:any) :Observable<any>{
    return this.apiService.post('/v1/applicants/'+id+'/scrutiny_comments',payload);
  }

  updateScrutinyStatus(id:any,payload:any) :Observable<any>{
    return this.apiService.put('/v1/applicants/'+id+'/scrutiny_status',payload);
  }

  fetchAllApplicantByPanchayat(id): Observable<any []> {
    return this.apiService.get('/v1/applicants?panchayat='+id);
  }

  fetchAllPanchayats(): Observable<any []> {
    return this.apiService.get('/v1/panchayat');
  }

  getAllLandTypes() :Observable<any>{
    return this.apiService.get('/v1/lookup/land_types');
  }

  getApplicantEnclosureList(id:any): Observable<any> {
    return this.apiService.get('/v1/applicants/'+id+"/enclosures");
  }

  fetchApplicantById(id:any): Observable<any> {
    return this.apiService.get('/v1/applicant/'+id);
  }

  saveSiteDetails(id:any, payload: any): Observable<any> {
    return this.apiService.put('/v1/applicants/'+id+'/site_details', payload);
  }

  saveSiteApproval(id:any, payload: any): Observable<any> {
    return this.apiService.put('/v1/applicants/'+id+'/site_approval', payload);
  }

  saveBuildingDetails(id:any, payload: any): Observable<any> {
    return this.apiService.put('/v1/applicants/'+id+'/building_details', payload);
  }

  uploadDocuments(id:any, payload: any): Observable<any> {
    return this.apiService.post('/v1/applicants/'+id+'/upload_document', payload);
  }

  uploadPropertyPicture(id:any, payload: any): Observable<any> {
    return this.apiService.put('/v1/applicants/'+id+'/upload_property_pic', payload);
  }

  getApplicantStatistics(): Observable<any> {
    return this.apiService.get('/v1/applicantStatitics');
  }

  getApplicantStatisticsByNotificationType(notificationType: any): Observable<any> {
    return this.apiService.get('/v1/applicantStatiticsByNotification/'+notificationType);
  }

  getApplicantCountByDate(): Observable<any> {
    return this.apiService.get('/v1/applicantCountByDate');
  }

  getApplicantCountByDateNotificationType(notificationType: any): Observable<any> {
    return this.apiService.get('/v1/applicantCountByDateAndNotificationType/'+notificationType);
  }

  getApplicantCountByDateFilter(fromDate, toDate): Observable<any> {
    return this.apiService.get('/v1/applicantCountByDate?fromDate='+fromDate+'&toDate='+toDate);
  }

  overrideApplication(applicationNo): Observable<any> {
    return this.apiService.post('/v1/overrideApplication/'+applicationNo);
  }

  auditHelpdeskEntry(payload): Observable<any> {
    return this.apiService.post('/v1/auditHelpdesk', payload);
  }

  getAuditHelpdeskStatistics(): Observable<any> {
    return this.apiService.get('/v1/auditHelpdeskStatitics');
  }

  getAuditHelpdeskStatisticsByDate(fromDate, toDate): Observable<any> {
    return this.apiService.get('/v1/auditHelpdeskStatitics?fromDate='+fromDate+'&toDate='+toDate);
  }

  updateLock(id:any,payload:any) :Observable<any>{
    return this.apiService.put('/v1/applicants/'+id+'/application_lock_status',payload);
  }

  getScrutinyUserCommentTypeRef() :Observable<any>{
    return this.apiService.get('/v1/scrutiny_user/comment_type_ref');
  }

  activateApplicantOneTimeEdit(applicantId: any): Observable<any> {
    return this.apiService.put('/v1/applicants/'+applicantId+'/activate_one_time_edit');
  }

  deActivateApplicantOneTimeEdit(applicantId: any): Observable<any> {
    return this.apiService.put('/v1/applicants/'+applicantId+'/deactivate_one_time_edit');
  }

  closeApplicantOneTimeEdit(applicantId: any): Observable<any> {
    return this.apiService.put('/v1/applicants/'+applicantId+'/close_one_time_edit');
  }
  fetchApplicantByVillages(villageId?:any,page?: any, size?: any): Observable<any> {
    if(!page) {
      page = 0;
   }
   if(!size) {
      size = 10;
   }
    return this.apiService.get('/v1/committee/applicantByVillages?villageId='+villageId+'&'+'page='+page+'&'+'size='+size)
  }
  fetchApplicantByVillagesAndNotificationType(notificationType: any,villageId?:any,page?: any, size?: any): Observable<any> {
    if(!page) {
      page = 0;
   }
   if(!size) {
      size = 10;
   }
    return this.apiService.get('/v1/committee/applicantByVillages/'+notificationType+'?villageId='+villageId+'&'+'page='+page+'&'+'size='+size)
  }
  getLeadershipApplicants(page?: any, pageSize?: any,applicationNo?:any,villageId?: any):Observable<any>{
    if(!page) {
      page = page + 1;
   }
   if(!pageSize) {
      pageSize = 10;
   }
   let url="/v1/leadershipUsers/applicants?page="+page+'&'+'pageSize='+pageSize;
   if(applicationNo) {
    url = url + "&applicationNo="+applicationNo;
  }
  
  if(villageId) {
    url = url + "&villageId="+villageId;
  }
   return this.apiService.get(url);
   }

   getLeadershipUsersApplicantsActivityLog(page?: any, size?:any, applicationNo?:any, statusCode?: any):Observable<any>{
    let user = this.storageService.getUser();
    if(!user) {
      throw new Error("User not logged in");
    }
    if(!page) {
      page = 0;
   }
    page = page + 1;
   if(!size) {
      size = 10;
   }
   let url='/v1/leadershipUsers/activityLogs?page='+page+'&'+'pageSize='+size;
   if(applicationNo){
    url = url + "&applicationNo="+applicationNo;
  }  
  if(statusCode) {
    url = url + "&statusCode="+statusCode;
  }
    return this.apiService.get(url);
  }

  updateApplicantStatus(id:any,payload:any): Observable<any>{
    return this.apiService.put('/v1/'+id+'/status',payload);
  }

  updateApplicantLeadershipStatus(id:any,payload:any): Observable<any>{
    return this.apiService.put('/v1/leadershipUsers/applicants/'+id+'/status', payload);
  }

  updateObservationLeadershipUsers(): Observable<any>{
    return this.apiService.get('/v1/observations');
  }


  getAllObservationsByRoleCode(): Observable<any []> {
    return this.apiService.get('/v1/observations');
  }

  fetchApplicantCountByStatus(notificationType?: any, villageId?: any): Observable<any []> {
    let url = "/v1/applicants/countByStatus";
    if(notificationType && villageId){
      url = url + "?notificationType="+notificationType + "&villageId="+villageId;
    }else{
      if(notificationType){
        url = url + "?notificationType="+notificationType;
      }  
      if(villageId) {
        url = url + "?villageId="+villageId;
      }
    }
    return this.apiService.get(url);
  }
  getApplicantDataByStatus(page?: any, size?:any, notificationType?:any, applicationNo?:any, villageId?:any, statusCode?: any):Observable<any>{
    if(!page) {
      page = 0;
   }
   if(!size) {
      size = 5;
   }
   let url='/v1/applicants/statusAndVillage?page='+page+'&size='+size;
   if(notificationType){
    url = url + "&notificationType="+notificationType;
  }  
  if(applicationNo) {
    url = url + "&keyword="+applicationNo;
  }
  if(villageId) {
    url = url + "&villageId="+villageId;
  }
  if(statusCode) {
    url = url + "&statusCode="+statusCode;
  }
    return this.apiService.get(url);
  }

  getObservationDataExcelsheet(): Observable<any>{
    return this.apiService.get('/v1/getObservationExcelSheet');
  }
}
