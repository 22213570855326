import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { map, startWith } from 'rxjs/operators';
import { ApplicationService } from 'src/services/application.service';
import { JccnoEncodeDecodeService } from 'src/services/jccno-encode-decode/jccno-encode-decode.service';
import { UserService } from 'src/services/user-service/user.service';
import { RoleConfigService } from '../shared/config/role/role.config.service';
import { StrorageService } from 'src/services/strorage.service';


@Component({
  selector: 'app-application-login-type',
  templateUrl: './application-login-type.component.html',
  styleUrls: ['./application-login-type.component.scss']
})
export class ApplicationLoginTypeComponent implements OnInit {
  notificationCode: any;
  heading: string;

  constructor(private applicationService : ApplicationService, private jccEncodeDecoder: JccnoEncodeDecodeService, 
    private router: Router, private storageService: StrorageService, 
    private roleConfig: RoleConfigService, private route:ActivatedRoute) {
  }
 
  public selectedApplication;
  notificationType: any;
  selectedNotificationType: string;

  public isExistingEnabled = false;

  public userName = "Username";

  myGroup = new FormGroup({
    
    applicationId:new FormControl()
 });
  options: any[] = [];
  filteredOptions: Observable<any[]>;

  ngOnInit() {
    // during creation remove already saved applicantId in the local storage
    window.localStorage.removeItem("applicantId");
    let notificationType = this.storageService.getNotificationType();
    this.notificationType = this.jccEncodeDecoder.decode(notificationType);
    let user = this.storageService.getUser();
    if(user['roleCode'] != this.roleConfig.ROLE_HELP_DESK_USER){
        this.router.navigate(['/']);
        return;
     } 
     if(this.notificationType) {
      this.heading = (this.notificationType == 'OLD') ? "Old Notification" : 'New Notification'; 
     }
     if(this.notificationType == 'NEW') {
      this.fetchApplicationNumbersByNotificationCode();
     }else {
      this.fetchApplicationNumbers();
     }
     this.userName = user['firstName'];
    }

  fetchApplicationNumbers(){
    this.applicationService.fetchApplicationNo(this.notificationType).subscribe(res =>{
      this.options = res;
      this.setFilterOptions();
    })
  }

  fetchApplicationNumbersByNotificationCode(){
    this.applicationService.fetchApplicationNo(this.notificationType).subscribe(res =>{
      this.options = res;
      this.setFilterOptions();
    })
  }

  selectOption(option: any){
    this.myGroup.get('applicationId').setValue(option);
    let applicationNumber = this.jccEncodeDecoder.encode(option);
    this.router.navigate(['/application_login'], { queryParams: { applicationNo:  applicationNumber} });
  }

  setFilterOptions(){
    this.filteredOptions = this.myGroup.get('applicationId').valueChanges
    .pipe(
      startWith(''),
      map(value => typeof value === 'string' ? value : value),
      map(name => name ? this._filter(name) : this.options.slice())
    );
  }

  setEnableExistance(){
    this.isExistingEnabled = true;
  }

  displayFn(user: any): string {
    return user && user.name ? user.name : '';
  }

  private _filter(name: string): any[] {
    const filterValue = name.toLowerCase();
    return this.options.filter(option => (option) ? option.toLowerCase().indexOf(filterValue) === 0 : false);
  }
  openNewApplication(){
    this.router.navigate(['/application_login'],{queryParams:{notificationCode:this.notificationCode}});
  }

}
