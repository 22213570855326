import { Component, Input, OnInit, Output,ViewChild, EventEmitter } from '@angular/core';
import { ApplicationService } from 'src/services/application.service';
import { Subscription } from 'rxjs';
import { ConfirmationModalComponent } from 'src/app/confirmation-modal/confirmation-modal.component';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { JccnoEncodeDecodeService } from 'src/services/jccno-encode-decode/jccno-encode-decode.service';
import { ActivatedRoute } from '@angular/router';
import { faTrash} from '@fortawesome/free-solid-svg-icons';
import { WebcamImage } from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';
import { CourtDetailsComponent } from '../court-details/court-details.component';

interface Year {
  year: string;
  years: string;
}

@Component({
  selector: 'app-property-details',
  templateUrl: './property-details.component.html',
  styleUrls: ['./property-details.component.scss']
})

export class PropertyDetailsComponent implements OnInit {

  @ViewChild(CourtDetailsComponent) courtDetailsComponent;
 

  public data : any;

  public isEditable : any;


  public menus: any = [
    {
      "name": "Property Details",
      "code": "property_details",
      "active": true,
      "default":true,
      "sub_menus":[]
    },
    {
      "name": "Court Details",
      "code": "court_details",
      "active": true,
      "default":true,
      "sub_menus":[]
    },
 
  ];

  public applicantName : string; 

  public telephoneNumber : number;

  public mobileNumber : number;

  public email : string;

  public surveyNumber : string;

  public aadharNumber : number;

  public villages : any[];

  public selectedVillage;

  public applicantAddress : string;
  
  public selectedMenu: any = {};

  private applicantId: any;

  public maxDate = new Date().getTime();

  public DateOfBirth: Date;

  public Gender : string;

  public yes :boolean;

  public no : boolean;

  public Married : string;

  public Unmarried : string;

  public cameraChoosen = false;

 public selectedFile;

 public selectedFileName;

 public profileImg : any ;

 faTrash = faTrash;

  @Output() newApplicantCallback = new EventEmitter<any>();

  @Output() nextScreenCallback = new EventEmitter<any>();



  yearOfAcquisition: any;
  surveyNo: any;
  public siteNo: any;
  nameoftheVillage: any;
  nameoftheScheme: any;
  compensationReceived: any;
  year: any;
  furnishLACNo: any;
  ddNo: any;
  lacNo: any;
  isLand: string;
  acquisitionYear: any;
  schemeName: any;
  saledeedRegDate: any;
  propertyDetailsSiteDimension:any;
  propertyDetailsSiteNo: any;
  isKhataIssued: any;
  isKhataIssuedByPanchayat: any;
  panchayats: any[];
  public surveys : any[];
  public acquiredPropSurveyNo: any;
  public selectedPanchayat: any;
  surveyNoHissa: any;
  
  constructor(private applicationService: ApplicationService,
     public dialog: MatDialog,
     private _snackBar: MatSnackBar, 
     private route: ActivatedRoute, 
     private jccEncodeDecoder: JccnoEncodeDecodeService) { }
  
  ngOnInit(): void {
    this.selectedMenu = this.menus[0];
    this.fetchApplication();
  }
  

  fetchPanchayats(data: any){
    this.applicationService.fetchAllPanchayats().subscribe(res =>{
       this.panchayats = res;
       if(data && data.panchayat) {
          this.selectedPanchayat = data.panchayat;
       }
    })
  }

  private fetchApplication = () => {
    let encodedNo = this.route.snapshot.queryParams["applicationNo"];
    if(encodedNo) {
      let applicantNo = this.jccEncodeDecoder.decode(this.route.snapshot.queryParams["applicationNo"]);
      this.fetchApplicationData(applicantNo);
    } else {
      this.applicantId = window.localStorage.getItem("applicantId");
      this.fetchApplicantById(this.applicantId);
    }
  }

  private fetchApplicantById = (applicantId: any) => {
    if(!applicantId) {
      return;
    }
    this.applicationService.fetchApplicantById(applicantId).subscribe(res =>{
      this.updateUI(res);
    })
  }

  onVillageChanged = (villageId:any, data?: any) => {
    this.applicationService.fetchSurveyNoByVillageId(villageId).subscribe(res=>{
       this.surveys =res;
       if(data) {
         this.surveyNo = data.acquiredPropSurveyNo;;
       }
     })
   }

  private updateUI = (res: any) => {
    this.applicantId = res.id;
    this.setEditable(res);
    this.data = res;
    this.setValue(res);
    this.newApplicantCallback.emit(res);
    //this.onVillageChanged(res.siteDetailsVillageVO.id, res);
    this.fetchPanchayats(res);
  }

  fetchApplicationData = (applicantNo: any) => {
    this.applicationService.fetchApplicantByApplicationNo(applicantNo).subscribe(res=>{
        console.log("basic personal details", res);
        this.updateUI(res);
    })
   }

  reset(){
    this.yearOfAcquisition = null;
    this.surveyNo = null;
    this.nameoftheVillage = null;
    this.nameoftheScheme = null;
    this.compensationReceived = null;
    this.year = null;
    this.furnishLACNo = null;
    this.ddNo = null;
    this.lacNo = null;
    this.isKhataIssued = null;
  }

  validateAndConstructPayload = () => {
      let payload = {};
      payload['propertyDetailsSiteDimension'] = this.propertyDetailsSiteDimension;
      payload['saledeedRegDate'] = this.saledeedRegDate;
      payload['isKhataIssuedByPanchayat'] = this.isKhataIssuedByPanchayat;
      payload['khataIssuedPanchayatId'] = undefined;
      if(this.isKhataIssuedByPanchayat) {
        payload['khataIssuedPanchayatId'] = this.selectedPanchayat;
      }
      return payload;
  }

  setValue(data: any): void{
    console.log('data ', data);
    this.selectedVillage = data.siteDetailsVillageVO.name;
    this.surveyNo = data.surveyMapperVO.surveyNo;
    this.surveyNoHissa=data.basicDetailsSurveyNoHissa;
    this.siteNo = data.siteNo;
    this.propertyDetailsSiteDimension = data.propertyDetailsSiteDimension;
    this.saledeedRegDate = data.saledeedRegDate;
    this.selectedPanchayat = data.selectedPanchayat;
    this.isKhataIssuedByPanchayat = data.isKhataIssuedByPanchayat;
  }

  public onItemClicked = (event: any) => {
    this.selectedMenu = event;
  }

  onSubmit = () => {
    this.openDialog();
  }

  openSnackbar(message: string, action?: string){
    if(!action) {
      action = "Dismiss";
    }
    this._snackBar.open(message, action);
  }

  openDialog = () => {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '160px';
    dialogConfig.width = '450px';
    dialogConfig.data = "Are you sure want to submit this record ?"
    const dialogRef = this.dialog.open(ConfirmationModalComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(res =>{
        if(res){
          if(this.applicantId) {
            this.updateApplicantBasicDetailsApiCall(this.applicantId);
            return;
          }
          this.saveApplicantBasicDetailsApiCall();
        }
    })
  }

  saveApplicantBasicDetailsApiCall = () => {
    try{
      let payload  = this.validateAndConstructPayload();
      this.applicationService.saveApplication(payload).subscribe(res =>{
        window.localStorage.setItem("applicantId", res.id);
        this.openSnackbar("Successfully Saved", "Dismiss");
        this.updateUI(res);
      }, err=>{
          console.log(err);
          this.openSnackbar(err?.message, "Dismiss");
      })
    } catch(e){
      this.openSnackbar(e, "Dismiss");
    }
  }

  private setEditable = (data: any): void => {
    if(data['oneTimeEdit'] != null) {
      this.isEditable = data['oneTimeEdit'];
    }
  }

  updateApplicantBasicDetailsApiCall = (applicantId: any) => {
    try{
      let payload  = this.validateAndConstructPayload();
      this.applicationService.saveSiteDetails(applicantId, payload).subscribe(res =>{
        this.openSnackbar("Successfully Updated", "Dismiss");
        this.updateUI(res);
      }, err => {
          console.log(err);
          this.openSnackbar(err?.message, "Dismiss");
      })
    } catch(e){
      this.openSnackbar(e, "Dismiss");
    }
  }
}
