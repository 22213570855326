<div class="container">
    <div>
        <app-top-bar selectedTab="home"></app-top-bar>
        <app-header></app-header>
    </div>
    <br>
   
    <div class="tool-bar">
        <!-- <div class="refresh-btn">
    
    </div>
     -->
    <div class="col-sm-1 ">
        <button class="btn btn-primary refresh-btn" (click)="refreshClick()">
            <fa-icon [icon]="faSync" class="close-icon" [spin]="isRefreshing"></fa-icon>
        </button>
                </div>
                <div class="refresh-btnn">
                    <button mat-button class="btn btn-primary"(click)="navigateToLeadershipHome()">Home</button>
                </div>
            
            <div class="col-sm-4 title">
                Committee Observations 
            </div>
                    
                 

        <div class="col-sm-3 offset-sm-1 user-details">
            <div class="value-text">
                Welcome  <br>
            {{user['firstName']}} !
            </div>
            </div>
        </div>

        
    <div>
        <div class="col-sm-1 ">
        <div> <button mat-button class=" btn btn-primary" (click)="showActivityLog()">Activity Log</button> </div>
    </div>
        <div class="col-sm-3 offset-sm-9">
            
            <div fxLayout="row" fxLayoutAlign="space-around">
            <input class="form-control border-end-0 border rounded-pill" style="font-size: 14px !important;"type="text" [(ngModel)]="applicationNo" placeholder="Search" id="applicationNo">
            <span class="input-group-append"><br>&nbsp;
             <button mat-button class="btn btn-primary " (click)="searchApplicant()">Search</button> 
             <button mat-button *ngIf="applicationNo" matSuffix mat-icon-button aria-label="Clear" (click)="onClear()">
                <mat-icon>close</mat-icon>
              </button>
            </span>
           
            </div>
        </div>
    </div>
    <div class="col-sm-2 offset-sm-9 village-details" >
        <mat-form-field appearance="fill">
            <mat-label>Village</mat-label>
            <mat-select id="village" name="villageId" [(ngModel)]="villageId">
              <mat-option *ngFor="let village of villages" [value]="village.id" (click)="onVillageChange()">
                {{village.id}} {{village.name}}
              </mat-option>
            </mat-select>

          </mat-form-field>
          <div class="p1">
          Total: {{totalSize}}
        </div>
    </div>
              
            
           
            <!-- data table -->
            <mat-card>
                <table mat-table [dataSource]="dataSource">
                    <ng-container matColumnDef="sn">
                        <th mat-header-cell *matHeaderCellDef> Serial No. </th>
                        <td mat-cell *matCellDef="let element ;let i =index;" class="column-container">{{i+1}}
                          </ng-container>
                    <!-- Position Column -->
                    <ng-container matColumnDef="applicationNo">
                        <th mat-header-cell *matHeaderCellDef> Application No. </th>
                        <td mat-cell *matCellDef="let element">
                            <a (click)="onPreviewClick(element.id)" class="preview-link">{{element.applicationNo}}</a>
                        </td>
                    </ng-container>
                    
                    <!-- Name Column -->
                    <ng-container matColumnDef="applicantName">
                        <th mat-header-cell *matHeaderCellDef> Name </th>
                        <td mat-cell *matCellDef="let element"> {{element.applicantName}} </td>
                    </ng-container>
                    
                    <!-- Telephone Column -->
                    <ng-container matColumnDef="channel">
                        <th mat-header-cell *matHeaderCellDef> Channel </th>
                            <td mat-cell *matCellDef="let element">
                                <div *ngIf = "element.applicantType == 'helpdesk_user'">
                                     {{'HD'}} 
                                </div>
                                <div *ngIf = "element.applicantType == 'online_application_user'">
                                    {{'Online'}} 
                               </div>
                            </td>
                    </ng-container>
                  
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" ></tr>
                </table>
                <div class="no-records-text" *ngIf="!dataSource.data || dataSource.data.length <= 0">No Records Found!</div>
                <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true" 
                [pageIndex]="currentPage" [length]="totalSize" (page)="getApplicantPaginatorData($event)">
            </mat-paginator>
            </mat-card>
 <app-footer></app-footer>
 </div>

