import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ApplicationService } from 'src/services/application.service';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { StrorageService } from 'src/services/strorage.service';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-leadership-users-activity-log',
  templateUrl: './leadership-users-activity-log.component.html',
  styleUrls: ['./leadership-users-activity-log.component.scss']
})
export class LeadershipUsersActivityLogComponent implements OnInit {
  public selectedStatusOption: any;
  public apiURL: string;
  public statusCode: any;
  public pageSize =5;
  public currentPage = 0;
  public totalSize: number= 0;
  public totalCount: number= 0;
  statusList: any = [];
  selectedStatus:any;
  selectedStatusOptionCode: any;
  totalApplicants: any;
  status_List:any=[];
  statusOptions: any = [];

  public displayedColumns: string[] = ['sn','applicationNo', 'applicantName','channel', 'observationDocumentURL'];
  
  public dataSource = new MatTableDataSource<any>();
  user: any;
  village: any;
  villageId: any;
  applicationNo = null;
  statusOption: any;
  statusOptionId:any;
  public totalApplicantCount: any = 0;

  constructor(private applicationService: ApplicationService,private cdr:ChangeDetectorRef, 
    private router: Router,private storageService:StrorageService) { }

   ngOnInit(): void {
    this.user=this.storageService.getUser();
  }

  ngAfterViewInit() {
    this.fetchTotalApplicantByStatus();
    this.fetchApplicantList();
  }

  public getApplicantPaginatorData(event: PageEvent) {
    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize;
    this.fetchApplicantList();
  }


  public fetchTotalApplicantByStatus(): void {
    let statusCode = undefined;
    if(this.selectedStatus && this.selectedStatus.statusCode != '') {
      statusCode = this.selectedStatus.statusCode;
    }
    // this.applicationService.fetchPiUserActivityLogCount(statusCode, this.villageId, this.applicationNo).subscribe(res =>{
    //   this.totalApplicantCount = res.data;
    // })
  }


  onClear() {
    this.applicationNo = null;
    this.fetchApplicantList();
  }

  public fetchApplicantList = () => {
    let statusCode: any;
    if(this.selectedStatus) {
      statusCode = this.selectedStatus.statusCode;
    }
    this.applicationService.getLeadershipUsersApplicantsActivityLog(this.currentPage,this.pageSize, 
      this.applicationNo, statusCode).subscribe(res => {
      this.refreshReportBatchesDataTable(res);
      console.log(res);
    })
  }
  
  // public getPiUserStatus=()=> {
  //   let statuses = [
  //     {
  //       "statusCode":"",
  //       "status":"All"
  //     },
  //     {
  //       "statusCode":"ready_for_reporting",
  //       "status":"Ready For Reporting"
  //     },
  //     {
  //       "statusCode":"out_of_bounds",
  //       "status":"Out of Bounds"
  //     },
  //   ];
  //   this.statusList = statuses;
  //   this.selectedStatus = statuses[0];
  // }
  searchApplicant(){
    this.fetchApplicantList();
  }

  private refreshReportBatchesDataTable = (res: any) => {
    this.dataSource.data = res.data;
    this.totalSize = res.totalRecord;
    this.currentPage = (res.currentPage - 1);
    this.dataSource._updateChangeSubscription();
    this.cdr.detectChanges();
  }

  clickBack(){
    window.history.back();

  }
  onPreviewClick(applicantId: any){
    this.router.navigate(['/leadership-users-activity-log-view'], {queryParams:{application_id:applicantId}});
   }
 
}