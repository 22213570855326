<div class="jumbotron">
    <div class="row">
        <div class="col-sm-10 offset-sm-1">
        <div class="row">
            <!-- <div class="col-sm-2 offset-md-1"> 
                
            </div> -->
         <div class="col-sm-12">
          <h1 class="header-text">{{ 'committee_headers.committee_text' | translate }}</h1>
        </div>
        
        </div>
    </div>
    </div>
        <div class="row">
        <div class="col-sm-12 committe-text">
            {{ 'committee_headers.committee_name' | translate }}
        </div>
        
        </div>
        </div>
    