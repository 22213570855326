import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ApplicationService } from 'src/services/application.service';
import { ApplicantAddressComponent } from './applicant-address/applicant-address.component';
import { BuildingDetailsComponent } from './building-details/building-details.component';
import { DetailsOfApprovalComponent } from './details-of-approval/details-of-approval.component';
import { BasicInfoComponent } from './basic-info/basic-info.component';
import { SiteDetailsComponent } from './site-details/site-details.component';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { AcknowledgementComponent } from './acknowledgement/acknowledgement.component';
import { UploadEnclosureComponent } from '../upload-enclosure/upload-enclosure.component';
import { StrorageService } from 'src/services/strorage.service';
import { JccnoEncodeDecodeService } from 'src/services/jccno-encode-decode/jccno-encode-decode.service';
import { UserService } from 'src/services/user-service/user.service';
import { AuthService } from 'src/services/auth.service';
import { RoleConfigService } from '../shared/config/role/role.config.service';

@Component({
  selector: 'app-application-form',
  templateUrl: './application-form.component.html',
  styleUrls: ['./application-form.component.scss']
})
export class ApplicationFormComponent implements OnInit {
  
  public menus: any = [
    {
      "name": "Basic Info",
      "code": "basic_details",
      "active": true,
      "default": true,
      "showMatCard": true,
      "class":'col-sm-2',
      "sub_menus":[
        {
          name:'Personal Details', 
          type: 'link',
          active: true,
          code: 'basic-info'
        }
      ]
    },
    {
      "name": "Personal Info",
      "code": "personal_info",
      "active": false,
      "default": false,
      "class":'col-sm-2',
      "showMatCard": true
    },
    {
      "name": "Property Details",
      "code": "property_details",
      "active": false,
      "default": false,
      "class":'col-sm-3',
      "showMatCard": true
    },
    
    {
      "name": "Enclosures",
      "code": "enclosures",
      "showMatCard": false,
      "active": false,
      "class":'col-sm-2',
      "default": false
    },
    {
      "name": "Acknowledgement",
      "code": "acknowledgement",
      "showMatCard": false,
      "active": false,
      "class":'col-sm-3',
      "default": false
    }
];

  public id;
  public applicationNo;
  public applicantName;
  public applicationId;
  public selectedPage = 1;
  public pageOneSelected = 1;
  public pageTwoSelected = 2;
  public pageThreeSelected = 3;
  public pageFourSelected = 4;
  public pageFiveSelected = 5;
  public pageName:string = 'personal';
  public isLoading:boolean = false;
  public isEditable = false;

  public selectedSubForm = 1;
  public selectedHeader;

  public applicationData;
  
  public showAcknowledgementForm: boolean = false;
  public showEnclsoureForm: boolean = false;

  public user: any;

  public selectedMenu: any;
  applicationType: any;
  heading: string;
  notificationType: any;
  status: any;


  constructor(private applicationService: ApplicationService, private authService: AuthService,
    private router: Router, private route: ActivatedRoute, private jccEncodeDecoder: JccnoEncodeDecodeService,
    private _snackBar: MatSnackBar, public dialog: MatDialog, private storageService: StrorageService, 
    private roleConfig: RoleConfigService) {
      this.user = storageService.getUser();
      let notificationType = this.storageService.getNotificationType();
      this.notificationType = this.jccEncodeDecoder.decode(notificationType);
      if(this.notificationType) {
        this.heading = (this.notificationType == 'OLD') ? "Old Notification" : 'New Notification'; 
      }
    }

  ngOnInit(): void {
    this.selectedMenu = this.menus[0];
  }

  public updateUI = (data: any): void => {
      this.applicationData = data;
      this.id = data.id;
      this.applicantName = data.applicantName;
      this.applicationNo = data.applicationNo;
      this.applicationId = data.applicationId;
      let applicationType = data.applicantType;
      if(applicationType == 'helpdesk_user') {
       this.applicationType = 'HD';
      }else{
        this.applicationType = 'Online';
      }
      this.status = data.statusVO.status;
  }

  ngAfterViewInit(){
    window.scroll(0,0);
  }

  openSelectedMenu = (menu: any) => {
    for(let i =0;i < this.menus.length;i++) {
      if(menu['code'] == this.menus[i]['code']) {
        this.menus[i]['active'] = true;
      } else {
        this.menus[i]['active'] = false;
      }
    }
    this.selectedMenu = menu;
  }

  public nextScreenCallback = (menu_code: any): void => {
    let selectedMenu: any;
    for(let i = 0;i < this.menus.length;i++) {
      if(menu_code == this.menus[i]['code']) {
        this.selectedMenu = this.menus[i];
      }
    }
    if(!selectedMenu) {
      return;
    }
    this.openSelectedMenu(selectedMenu);
  }

  public oneTimeEditCallback = (): void => {
    let menuCode = "basic_details";
    this.nextScreenCallback(menuCode);
  }
}
