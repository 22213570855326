<div class="jumbotron">
<div class="row">
    <div class="col-sm-10 offset-sm-1">
    <div class="row">
        
     <div class="col-sm-12">
      <h1 class=" header-text">{{ 'content_headers.commitee_text' | translate }}</h1>
    </div>
   
    </div>
</div>
</div>
    <div class="row">
        <div class="col-sm-12 committe-text">
        {{ 'content_headers.commitee_name' | translate }}
    </div>
    
    </div>
    </div>
