import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/services/language-service/language.service';
import { StrorageService } from 'src/services/strorage.service';
import { UserService } from 'src/services/user-service/user.service';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent implements OnInit {
  @Input() selectedTab: string; 
  
  public homeTab = 'home';
  public publicInfoTab = 'publicInfo';
  public newsTab = 'news';
  public contactTab = 'contact';
  public auditTab = 'audit';

  public choosenLanguage = 'en';

  public isLoggedIn = false;

  public isPublicInfoChoosed = false;

  public userType;

  private user: any;

  constructor(private router: Router, private translate: TranslateService, 
    public dialog: MatDialog, private _snackBar: MatSnackBar, private storageService: StrorageService, 
    private languageService: LanguageService) {  
    let preferredLang = this.languageService.getLanguage();
    if(preferredLang){
      this.choosenLanguage = preferredLang;
      this.translate.setDefaultLang(preferredLang);
    }
    else{
      this.translate.setDefaultLang('en');
    }
    this.user = storageService.getUser();
    if(this.user) {
      this.userType = this.user['roleCode'];
    }
  } 

  ngOnInit(): void {
    if(this.storageService.getAccessToken()){
       this.isLoggedIn = true;
    }
  }

  selectContact(){
    this.selectedTab = this.contactTab;
    this.isPublicInfoChoosed = false;
    this.router.navigate(['./contact'])
  }
  selectHome(){
    this.selectedTab = this.homeTab;
    this.isPublicInfoChoosed = false;
    this.router.navigate(['./'])
  }
  selectAudit(){
    this.selectedTab = this.auditTab;
    this.isPublicInfoChoosed = false;
    if(this.userType && this.userType == 'audit_helpdesk_user'){
      this.router.navigate(['/audit-helpdesk']);
    } else {
      this.router.navigate(['./audit-helpdesk-login'])
    }  
  }

  selectPublicInfo(){
    this.selectedTab = this.publicInfoTab;
     this.isPublicInfoChoosed = true;
  }

  selectNews(){
    this.selectedTab = this.newsTab;
    this.isPublicInfoChoosed = false;
  }

  chooseEnglish(){
    this.translate.setDefaultLang('en');
    this.choosenLanguage = 'en';
    this.languageService.setLanguage('en');
  }

  chooseKannada(){
    this.translate.setDefaultLang('kn');
    this.choosenLanguage = 'kn';
    this.languageService.setLanguage('kn');
  }

  logout = () => {

    this.storageService.clear();
    this.isLoggedIn = false;
    if(this.userType == "helpdesk_user"){
      this.router.navigate(['/helpdesk-login']);
    }
    else if(this.userType == "online_application_user"){
      this.router.navigate(['/online-application-login']);
    }
    else if(this.userType == "comittee_user"){
      this.router.navigate(['/committee-login']);
    }
    else if(this.userType == "scrutiny_user"){
      this.router.navigate(['/scrutiny-login']);
    }
     else if(this.userType == "admin_user"){
      this.router.navigate(['/admin-login']);
     }
     else if(this.userType == "audit_helpdesk"){
      this.router.navigate(['/audit-helpdesk-login']);
     }
     else{
      this.router.navigate(['']);
     }
  }



  onFAQClick(){
    window.open('https://'+window.location.hostname+'/images/JCC-FAQ.pdf');
  }
  onInstructionsClick(){
    window.open('https://'+window.location.hostname+'/images/Instructions-online-Application.pdf'); 
  }
  onlineSop(){
    window.open('https://'+window.location.hostname+'/images/Online-applications-sop.pdf'); 

  }

  openLogoutDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '160px';
    dialogConfig.width = '360px';
    dialogConfig.data = "Are you sure want to logout ?"
    const dialogRef = this.dialog.open(ConfirmationModalComponent,dialogConfig);
    dialogRef.afterClosed().subscribe(res =>{
        if(res){
          this.logout();
        }
    })
  }
  openSnackbar(message: string, action: string){
    this._snackBar.open(message, action);
  } 

}
