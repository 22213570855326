<div class="content-area">
<div class="col-sm-12">
   <div class="row">
        <div class="col-md-8">
            <fa-icon [icon]="faCheck" class="checked" [hidden]="!enclosure || !enclosure.applicationFormCopy"></fa-icon>
            <fa-icon [icon]="faTimes" class="unchecked" [hidden]="!enclosure || enclosure.applicationFormCopy"></fa-icon>
            <label> 1. ಪೂರ್ಣ ಅಂಚೆ ವಿಳಾಸ ನಮೂದಿಸಿರುವ ಅರ್ಜಿ/ Prescribed Application form with Complete Address
            </label>
        </div>
        <div class="col-md-4">
            <input type="file" id="appform_cpy" [disabled]="!enclosure || enclosure.applicationFormCopy"  name="appform_cpy" (change)="onFileChanged($event)"/>
            <fa-icon [icon]="faTrash" class="dismiss-icon" (click)="dismissIconClick('appform_cpy')"></fa-icon>
        </div>
        
    </div>

    <div class="row">
        <div class="col-md-8">
            <fa-icon [icon]="faCheck" class="checked" [hidden]="!enclosure || !enclosure.aadharCard"></fa-icon>
            <fa-icon [icon]="faTimes" class="unchecked" [hidden]="!enclosure || enclosure.aadharCard"></fa-icon>
            <label> 2.ವಿಳಾಸವಿರುವ ಆಧಾರ್ ಕಾರ್ಡ್ ಪ್ರತಿ / Aadhar Card Copy with self-address</label>
        </div>
        <div class="col-md-4">
            <input type="file" id="aadhar_card" [disabled]="!enclosure || enclosure.aadharCard" name="aadhar_card" (change)="onFileChanged($event)"/>
            <fa-icon [icon]="faTrash" class="dismiss-icon" (click)="dismissIconClick('aadhar_card')"></fa-icon>
        </div>
    </div>

    <div class="row">
        <div class="col-md-8">
            <fa-icon [icon]="faCheck" class="checked" [hidden]="!enclosure || !enclosure.panCard"></fa-icon>
            <fa-icon [icon]="faTimes" class="unchecked" [hidden]="!enclosure || enclosure.panCard"></fa-icon>
            <label>3. ಪ್ಯಾನ್ ಕಾರ್ಡ್ ಜೆರಾಕ್ಸ್/ Copy of the Pan Card</label>
        </div>
        <div class="col-md-4">
            <input type="file" id="pan_cpy" [disabled]="!enclosure || enclosure.panCard" name="pan_cpy" (change)="onFileChanged($event)"/>
            <fa-icon [icon]="faTrash" class="dismiss-icon" (click)="dismissIconClick('pan_cpy')"></fa-icon>
        </div>
    </div>

    <div class="row">
        <div class="col-md-8">
            <fa-icon [icon]="faCheck" class="checked" [hidden]="!enclosure || !enclosure.passportPic"></fa-icon>
            <fa-icon [icon]="faTimes" class="unchecked" [hidden]="!enclosure || enclosure.passportPic"></fa-icon>
            <label>4. ಪಾಸ್ ಪೋರ್ಟ್ ಅಳತೆಯ ಭಾವಚಿತ್ರ – ೨ / Passsport picture of the Applicant – 2 No.</label>
        </div>
        <div class="col-md-4">
            <input type="file" id="passport_pic" [disabled]="!enclosure || enclosure.passportPic" name="passport_pic" (change)="onFileChanged($event)"/>
            <fa-icon [icon]="faTrash" class="dismiss-icon" (click)="dismissIconClick('passport_pic')"></fa-icon>
        </div>
    </div>

    <div class="row">
        <div class="col-md-8">
            <fa-icon [icon]="faCheck" class="checked" [hidden]="!enclosure || !enclosure.notorizedSelfAffidavit"></fa-icon>
            <fa-icon [icon]="faTimes" class="unchecked" [hidden]="!enclosure || enclosure.notorizedSelfAffidavit"></fa-icon>
            <label>5. ಅರ್ಜಿದಾರ ಹಾಗೂ ತಮ್ಮ ಅವಲಂಬಿತರ ಹೆಸರಿನಲ್ಲಿ ಬೇರೆ ಯಾವುದೇ ನಿವೇಶನ ಹಾಗೂ ಮನೆ ಹೊಂದಿಲ್ಲ ಎಂಬ ಬಗ್ಗೆ ಪ್ರಮಾಣ ಪಾತ್ರ (ನೋಟರೈಸ್ಡ್) / Self-Affidavit duly notarized to show that the applicant or their dependents are not owning any site/house</label>
        </div>
        <div class="col-md-4">
            <input type="file" id="notorizedSelfAffidavit" name="notorizedSelfAffidavit" [disabled]="!enclosure || enclosure.notorizedSelfAffidavit" (change)="onFileChanged($event)"/>
            <fa-icon [icon]="faTrash" class="dismiss-icon" (click)="dismissIconClick('notorizedSelfAffidavit')"></fa-icon>
        </div>
    </div>
    <br>
    
    <div class="row">
        <div class="col-md-8">
            <fa-icon [icon]="faCheck" class="checked" [hidden]="!enclosure || !enclosure.regnFeeConsentAffidavit"></fa-icon>
            <fa-icon [icon]="faTimes" class="unchecked" [hidden]="!enclosure || enclosure.regnFeeConsentAffidavit"></fa-icon>
            <label>6. ಪ್ರಾಧಿಕಾರವು ನಿಗದಿಪಡಿಸಬಹುದಾದ ಶುಲ್ಕ ಪಾವತಿಸುವ ಬಗ್ಗೆ ಒಪ್ಪಿಗೆ ಪ್ರಮಾಣಪತ್ರ (ನೋಟರೈಸ್ಡ್)/ Consenting affidavit duly notarized stating that the applicant is agreeable to pay allotment rate to be fixed by the BDA</label>
        </div>
        <div class="col-md-4">
            <input type="file" id="regn_fee" name="regn_fee" [disabled]="!enclosure || enclosure.regnFeeConsentAffidavit" (change)="onFileChanged($event)"/>
            <fa-icon [icon]="faTrash" class="dismiss-icon" (click)="dismissIconClick('regn_fee')"></fa-icon>
        </div>
    </div>
    <br>

    <div class="row">
        <div class="col-md-8">
            <fa-icon [icon]="faCheck" class="checked" [hidden]="!enclosure || !enclosure.saleDeed"></fa-icon>
            <fa-icon [icon]="faTimes" class="unchecked" [hidden]="!enclosure || enclosure.saleDeed"></fa-icon>
            <label>7. ನೋಂದಾಯಿತ ಕ್ರಯಪತ್ರದ ಧೃಡೀಕೃತ ಪ್ರತಿ / Certified copy of the Registered Sale deed</label>
        </div>
        <div class="col-md-4">
            <input type="file" id="sale_deed" name="sale_deed" [disabled]="!enclosure || enclosure.saleDeed" (change)="onFileChanged($event)"/>
            <fa-icon [icon]="faTrash" class="dismiss-icon" (click)="dismissIconClick('sale_deed')"></fa-icon>
        </div>
    </div>

    <!-- <div class="row">
        <div class="col-md-8">
            <fa-icon [icon]="faCheck" class="checked" [hidden]="!enclosure || !enclosure.otherOwnershipDocs"></fa-icon>
            <fa-icon [icon]="faTimes" class="unchecked" [hidden]="!enclosure || enclosure.otherOwnershipDocs"></fa-icon>
            <label>8.ಇತರೆ ಹಕ್ಕು ದಾಖಲಾತಿಗಳು(ದಾನಪತ್ರ, ವಿಲ್ ಇತ್ಯಾದಿ) / Documents to show the ownership of revenue site (Copy of Gift deed/Will / etc..)</label>
        </div>
        <div class="col-md-4">
            <input type="file" id="other_ownerShip_docs" name="other_ownerShip_docs" [disabled]="!enclosure || enclosure.otherOwnershipDocs" (change)="onFileChanged($event)"/>
            <fa-icon [icon]="faTrash" class="dismiss-icon" (click)="dismissIconClick('other_ownerShip_docs')"></fa-icon>
        </div>
    </div> -->
    <br> 

    <div class="row">
        <div class="col-md-8">
            <fa-icon [icon]="faCheck" class="checked" [hidden]="!enclosure || !enclosure.encumberanceCert"></fa-icon>
            <fa-icon [icon]="faTimes" class="unchecked" [hidden]="!enclosure || enclosure.encumberanceCert"></fa-icon>
            <label> 8. ನಿವೇಶನ ಖರೀದಿಸಿದ ದಿನಾಂಕದಿಂದ  ಈ ತನಕದ ಋಣಭಾರ / ಋಣಭಾರರಾಹಿತ್ಯ ಪತ್ರ (ಇ . ಸಿ) - ನಮೂನೆ - 15 / Encumbrance Certificate in form 15 from the date of purchase of revenue site till date</label>
        </div>
        <div class="col-md-4">
            <input type="file" id="encumberance_cert" name="encumberance_cert" [disabled]="!enclosure || enclosure.encumberanceCert" (change)="onFileChanged($event)"/>
            <fa-icon [icon]="faTrash" class="dismiss-icon" (click)="dismissIconClick('encumberance_cert')"></fa-icon>
        </div>
    </div>
    <br>

    <div class="row">
        <div class="col-md-8">
            <fa-icon [icon]="faCheck" class="checked" [hidden]="!enclosure || !enclosure.layoutPlan"></fa-icon>
            <fa-icon [icon]="faTimes" class="unchecked" [hidden]="!enclosure || enclosure.layoutPlan"></fa-icon>
            <label>9. ರೆವೆನ್ಯೂ ನಿವೇಶನವಿರುವ ಬಡಾವಣೆಯ ನಕ್ಷೆ (ಸರ್ವೆ ನಂಬರ್ ಮತ್ತು ರೆವೆನ್ಯೂ ನಿವೇಶನ ಸಂಖ್ಯೆ ಇರುವ ವಿವರ)/ Layout plan duly demarcating the revenue site (Sy. No. Village and Revenue Site No.) </label>
        </div>
        <div class="col-md-4">
            <input type="file" id="layout_plan" name="layout_plan" [disabled]="!enclosure || enclosure.layoutPlan" (change)="onFileChanged($event)"/>
            <fa-icon [icon]="faTrash" class="dismiss-icon" (click)="dismissIconClick('layout_plan')"></fa-icon>
        </div>
    </div>
    <br>

    <div class="row">
        <div class="col-md-8">
            <fa-icon [icon]="faCheck" class="checked" [hidden]="!enclosure || !enclosure.taxPaidReceipt"></fa-icon>
            <fa-icon [icon]="faTimes" class="unchecked" [hidden]="!enclosure || enclosure.taxPaidReceipt"></fa-icon>
            <label>10. ಸ್ಥಳೀಯ ಸಂಸ್ಥೆಗೆ / ಇಲಾಖೆಗೆ ಸಂಬಂಧಿಸಿಧ ಪಾವತಿಸಿರುವ ಕಂದಾಯ ಪಾವತಿ ರಸೀದಿ/ Copy of the latest tax paid to local bodies/concerned department</label>
        </div>
        <div class="col-md-4">
            <input type="file" id="taxPaidReceipt" name="taxPaidReceipt"  [disabled]="!enclosure || enclosure.taxPaidReceipt"  (change)="onFileChanged($event)"/>
            <fa-icon [icon]="faTrash" class="dismiss-icon" (click)="dismissIconClick('taxPaidReceipt')"></fa-icon>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col-md-8">
            <fa-icon [icon]="faCheck" class="checked" [hidden]="!enclosure || !enclosure.domCert"></fa-icon>
            <fa-icon [icon]="faTimes" class="unchecked" [hidden]="!enclosure || enclosure.domCert"></fa-icon>
            <label>11. ವಾಸ ಪ್ರಮಾಣಪತ್ರ / Domicile Certificate </label>
        </div>
        <div class="col-md-4">
            <input type="file" id="dom_cert" name="dom_cert" [disabled]="!enclosure || enclosure.domCert" (change)="onFileChanged($event)"/>
            <fa-icon [icon]="faTrash" class="dismiss-icon" (click)="dismissIconClick('dom_cert')"></fa-icon>
        </div>
    </div>

    <div class="row">
        <div class="col-md-8">
            <fa-icon [icon]="faCheck" class="checked"  [hidden]="!enclosure || !enclosure.regnFeepaidChallan"></fa-icon>
            <fa-icon [icon]="faTimes" class="unchecked" [hidden]="!enclosure || enclosure.regnFeepaidChallan"></fa-icon>
            <label>12. ನೋಂದಣಿ ಶುಲ್ಕ ಪಾವತಿಸಿದ ರಸೀದಿ  / Registration Fee paid challan </label>
        </div>
        <div class="col-md-4">
            <input type="file" id="regn_feepaid_challan" name="regn_feepaid_challan"  [disabled]="!enclosure || enclosure.regnFeepaidChallan" (change)="onFileChanged($event)"/>
            <fa-icon [icon]="faTrash" class="dismiss-icon" (click)="dismissIconClick('regn_feepaid_challan')"></fa-icon>
        </div>
    </div>

    <div class="row">
        <div class="col-md-8">
            <fa-icon [icon]="faCheck" class="checked" [hidden]="!enclosure || !enclosure.formAAffidavit"></fa-icon>
            <fa-icon [icon]="faTimes" class="unchecked" [hidden]="!enclosure || enclosure.formAAffidavit"></fa-icon>
            <label>13. ಫಾರಂ 'A" ನಲ್ಲಿನ ಅಫಿಡವಿಟ್ / Affidavit in Form ‘A’ </label>
        </div>
        <div class="col-md-4">
            <input type="file" id="form_affidavit" name="form_affidavit" [disabled]="!enclosure || enclosure.formAAffidavit" (change)="onFileChanged($event)"/>
            <fa-icon [icon]="faTrash" class="dismiss-icon" (click)="dismissIconClick('form_affidavit')"></fa-icon>
        </div>
    </div>

    <div class="row">
        <div class="col-md-8">
            <fa-icon [icon]="faCheck" class="checked" [hidden]="!enclosure || !enclosure.courtCaseAffidavit"></fa-icon>
            <fa-icon [icon]="faTimes" class="unchecked" [hidden]="!enclosure || enclosure.courtCaseAffidavit"></fa-icon>
            <label>14. ನ್ಯಾಯಾಲಯದಲ್ಲಿ ಸಂಬಂಧಿತ  ರೆವೆನ್ಯೂ ನಿವೇಶನದ ಕುರಿತು ಯಾವುದೇ ದಾವೆ ಹೂಡಿದ್ದಲ್ಲಿ ಸದರಿ ದಾವೆಯನ್ನು ಹಿಂಪಡೆದಿದ್ದಕ್ಕೆ ಅಫಿಡವಿಟ್ ಹಾಗು ನ್ಯಾಯಾಲಯದ ಪ್ರಮಾಣ ಪಾತ್ರ  /Affidavit & Certified copy from concerned courts indicating withdrawal of cases pertaining to the said Revenue site</label>
        </div>
        <div class="col-md-4">
            <input type="file" id="courtCaseAffidavit" name="courtCaseAffidavit" [disabled]="!enclosure || enclosure.courtCaseAffidavit"  (change)="onFileChanged($event)"/>
            <fa-icon [icon]="faTrash" class="dismiss-icon" (click)="dismissIconClick('courtCaseAffidavit')"></fa-icon>
        </div>
    </div>

    <div class="row">
        <div class="col-md-8">
            <fa-icon [icon]="faCheck" class="checked" [hidden]="!enclosure || !enclosure.otherRelatedDocs"></fa-icon>
            <fa-icon [icon]="faTimes" class="unchecked" [hidden]="!enclosure || enclosure.otherRelatedDocs"></fa-icon>
            <label>15. ಇತರೆ ಸಂಬಂಧಿತ ದಾಖಲೆಗಳು / Other Related Documents</label>
        </div>
        <div class="col-md-4">
            <input type="file" id="otherRelatedDocs" name="otherRelatedDocs" [disabled]="!enclosure || enclosure.otherRelatedDocs"  (change)="onFileChanged($event)"/>
            <fa-icon [icon]="faTrash" class="dismiss-icon" (click)="dismissIconClick('otherRelatedDocs')"></fa-icon>
        </div>
    </div>
    <div class="row">
        <div class="col-md-2 offset-md-5 button-row">
            <input type="button" class="btn btn-primary" value="Save" (click)="onSubmit()"/>
        </div>
    </div>
</div>