<div fxFlex="100%">
        <div fxFlex="25" class="side-menu-container">
            <app-side-menu [menus]="menus" (onMenuClick)="onItemClicked($event)"></app-side-menu>
        </div>
       
        <div fxFlex="75" style="padding-left: 55px">
            <div fxLayout="row" fxLayoutAlign="space-between">
            <div style="width: 40%"  class="row application-form-row">
                <label class="form-label"> ನೋಂದಣಿ ಸಂಖ್ಯೆ / Registration & Application No: <span class="text-danger"> * </span></label>
                <input type="text" [(ngModel)]="registrationNo" class="form-textbox" [readonly]="(data && data.applicationNo) && !isEditable" />
            </div>
            <div style="width: 35%"  class="row application-form-row">
                <label class="form-label">ನೋಂದಣಿ ದಿನಾಂಕ/Date Of Registration : <span class="text-danger"> * </span></label>
                <input type="date" max="{{ maxDate |date:'yyyy-MM-dd'}}"   onkeydown=" false" [(ngModel)]="dateofRegister" class="form-textbox" [readonly] = "(data && data.registrationDate) && !isEditable"/>
            </div>
            <div style="width: 25%"  class="row application-form-row">
                <label class="form-label"> ನೋಂದಣಿ ಶುಲ್ಕ / Regn Fee: </label>
                <input type="number" [(ngModel)]="registrationFees" class="form-textbox" [readonly]="(data && data.registrationFees) && !isEditable" />
            </div>
        </div>
            
            <div class="row application-form-row">
                <label class="form-label">ಅರ್ಜಿದಾರನ ಹೆಸರು / Name Of The Applicant : <span class="text-danger"> * </span></label>
                <input type="text" [(ngModel)]="applicantName" class="form-textbox" [readonly]="(data && data.applicantName) && !isEditable" />
            </div>
            <div class="row application-form-row ">
                    <label class="form-label">ತಂದೆ/ಗಂಡನ ಹೆಸರು/Father’s/Husband’s Name: <span class="text-danger"> * </span></label>
                    <input type="text" [(ngModel)]="fatherName" class="form-textbox" [readonly] = "(data && data.fatherName) && !isEditable" />
            </div>

            <div class="row application-form-row ">
                    <label class="form-label">ಮೊಬೈಲ್ ಸಂಖ್ಯೆ / Mobile No (10 digits Only): <span class="text-danger"> * </span></label>
                    <input type="number" [(ngModel)]="mobileNumber" class="form-textbox" [readonly] = "(data && data.mobileNumber) && !isEditable" />
            </div>
            <div class="row application-form-row ">
                <label class="form-label">ಇ-ಮೇಲ್/E-mail:    </label>
                <input type="text" [(ngModel)]="email" class="form-textbox" [readonly] = "(data && data.email) && !isEditable" />
        </div>
        <div  class="row application-form-row " fxLayout="row"fxLayoutAlign="space-between">
            <label class="form-label">ಅರ್ಜಿದಾರನ ಅಂಚೆ ವಿಳಾಸ/Postal Address of the Applicant : 
                <span class="text-danger"> * </span></label>
            <input type="text" [(ngModel)]="address" class="form-textbox" [readonly] = "(data && data.applicantAddress) && !isEditable"/>
          </div>
            <div class="row application-form-row ">
                    <label class="form-label"> ನಗರ / City : <span class="text-danger"> * </span> </label>
                    <input type="text" [(ngModel)]="city" class="form-textbox" [readonly] = "(data && data.applicantCity) && !isEditable"/>
            </div>

            <div fxLayout="row" fxLayoutAlign="space-between">
                <div style="width: 50%"  class="row application-form-row">
                    <label class="form-label"> ರಾಜ್ಯ / State/Province :
                        <span class="text-danger"> * </span></label>
                 <input type="text" [(ngModel)]="state" class="form-textbox" [readonly]="(data && data.state) && !isEditable" />
                </div>
                <div style="width: 50%"  class="row application-form-row">
                    <label class="form-label"> ದೇಶ/ Country :  
                        <span class="text-danger"> * </span></label>
                        <input type="text" [(ngModel)]="country" class="form-textbox" [readonly]="(data && data.country) && !isEditable" />
                </div>
            </div> 
            
                    <div class="row application-form-row ">
                            <label class="form-label">ಪಿನ್ ಕೋಡ್ / Pin Code : </label>
                            <input type="text" [(ngModel)]="pinCode" class="form-textbox" [readonly] = "(data && data.pincode) && !isEditable" />
                        </div>
                        <div fxLayout="row" fxLayoutAlign="space-between">
                            <div style="width: 100%"  class="row application-form-row">
                                <label class="form-label"> ತಮ್ಮ ರೆವಿನ್ಯೂ ನಿವೇಶನವಿರುವ ಗ್ರಾಮದ ಹೆಸರು/Village where your revenue site is located :<span class="text-danger"> * </span></label>
                                <select class="form-textbox" [(ngModel)] = "selectedVillage" (ngModelChange)="onVillageChanged($event)" [disabled]="(data && data.siteDetailsVillageVO) && !isEditable" >
                                    <option disabled="true">Select Village</option>
                                    <option *ngFor = "let village of villages" [value]="village.id">{{village.id}}. {{village.name}}</option>
                                </select>
                            </div>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="space-between">
                            <div style="width: 50%"  class="row application-form-row">
                                <label class="form-label">ಸರ್ವೇ ನಂ./Survey No:
                                    <span class="text-danger"> * </span></label>
                                    <select class="form-textbox" [(ngModel)] = "surveyNo" [disabled]="(data && data.surveyMapperVO) && !isEditable" >
                                        <option disabled="true">Select Survey No</option>
                                        <option *ngFor = "let survey of surveys" [value]="survey.id">{{survey.surveyNo}} </option>
                                    </select>
                            </div>
                            <div style="width: 50%"  class="row application-form-row">
                                <label class="form-label"> ಸರ್ವೇ ನಂ./ Survey No  ಹಿಸ್ಸಾ/ Hissa :<span class="text-danger"> * </span>
                                </label>
                                    <input type="text" [(ngModel)]="basicDetailsSurveyNoHissa" class="form-textbox" [readonly]="(data && data.basicDetailsSurveyNoHissa) && !isEditable" />
                            </div>
                        </div> 
                      
                        <div class="row application-form-row ">
                            <label class="form-label">ನಿವೇಶನ ಸಂಖ್ಯೆ / Site No : <span class="text-danger"> * </span></label>
                            <input type="text" [(ngModel)]="siteNo" class="form-textbox" [readonly] = "(data && data.siteNo) && !isEditable" />
                        </div>     
            <div fxLayout="row" style="margin-top: 20px">
                <button fxFlexOffset="70" class="btn btn-next" (click)="onSubmit()">Save & Continue</button>
            </div>
        </div>
</div>
    