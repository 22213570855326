import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { ProfileInfoModalComponent } from '../profile-card/profile-info-modal/profile-info-modal.component';

@Component({
  selector: 'app-profile-card-committee',
  templateUrl: './profile-card-committee.component.html',
  styleUrls: ['./profile-card-committee.component.scss']
})
export class ProfileCardCommitteeComponent implements OnInit {
  dialog: any;
  isReadMore: boolean;
  @Input() imagePath: string;
  @Input() content: string; 
  @Input() name: string; 




  constructor() { }

  ngOnInit(): void {
  }
  openDialog() {
    let data = {};
    data['title'] = this.name;
    data['content'] = this.content
  const dialogConfig = new MatDialogConfig();
  dialogConfig.disableClose = false;
  dialogConfig.autoFocus = true;
  dialogConfig.height = '50vh';
  dialogConfig.width = '32vw';
  dialogConfig.data = data;
  const dialogRef = this.dialog.open(ProfileInfoModalComponent,dialogConfig);

}

onReadMoreClick(){
  this.isReadMore = !this.isReadMore;
}

}
