<div class ="container">
    <div class="row content-area"><br>
        <br>
        <br>
        <div class="col-sm-12 title">
                SCRUTINY WORKBENCH
        </div>
        <div class ="col-sm-11">
        <app-applicant-view [data]=applicationData></app-applicant-view>
       
            <br>
            <div class="label col-sm-10" *ngIf = "applicationData">  
            <div class="row">
                <div class="col-sm-8" *ngIf='applicationData.comment!=null'>
                    <label >Comments</label>
                </div>
                <div class="col-sm-4">
                    <label >{{applicationData.comment}}</label>
                  </div>
            </div>
            <br>
            <div>
            <br>
            <div class="row"  *ngIf='status!=null'>
                <div class="col-sm-8 ">
                    <label>Status</label>
                </div>
                <div class="col-sm-4">
                    <label class="color">{{status.status}}</label>
                  </div>
            </div>
           <br>
            <div fxLayout="row" fxLayoutGap="30px" fxLayoutAlign="space-around">
                <div *ngFor="let message of commentTypes">
                    <mat-checkbox [(ngModel)]="message.checked" [disabled]=disable>{{message.label}}</mat-checkbox>
                </div>
            </div>
             <br>
            </div>
            </div>
            </div>
        </div>
        
    </div>
    

    