<div class ="container">
    <div class="row content-area"><br>
        <br>
        <br>
        <div class="col-sm-12 title">
            JUDGE’S OBSERVATION WORKBENCH
        </div>
        <div class="label col-sm-10" >
      
            <div class ="col-sm-12 text-title">DSKL NO:{{applicantName}}</div>
        <div >
            <button (click)="clickBack()"class="back-link">Back</button>
         </div>
       <div>
        <button  (click) ="encloPage()" class="enclosure-link">Enclosure </button> 
    </div> 

          
          
     <br>
    <br>
    <br>
              
            </div>
        
            </div>

            <div class="row application-form-row ">
                <div fxLayout="row" fxFlex="100" *ngFor="let question of questions;let i = 'index+1'">
                    <div fxLayout="row" fxFlex="100">
                        <div fxLayout="column" fxFlex="50" ><label class="form-label" style="margin-top: 8px;">{{i}}. {{question.question}} <span class="text-danger"> * </span></label>
                            <div fxLayout="row" fxFlex="100" *ngIf="question.selectedValue == 'yes'">
                                <div fxLayout="row" fxFlex="100" *ngFor="let subQuestion of question.subQuestions">
                                    <div fxLayout="column" fxFlex="50" style="margin-left: 4%;min-width: 66%;margin-top:4%;"><label class="form-label">{{i}}.a) {{subQuestion.question}} </label></div>
                                    <div fxLayout="column" fxFlex="50">
                                        <div fxLayout="row" fxFlex="100">
                                            <mat-radio-group  [(ngModel)]="subQuestion.selectedValue" style="margin-left: 100%;min-width: 109%;margin-top:6%;">
                                                <mat-radio-button value="yes">Yes</mat-radio-button>
                                                <mat-radio-button value="no">No</mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                    </div>
                                </div>
                            </div>                       </div>
                        <div fxLayout="column" fxFlex="50" >
                            <div fxLayout="row" fxFlex="100">
                                <mat-radio-group  [(ngModel)]="question.selectedValue" >
                                    <mat-radio-button value="yes">Yes</mat-radio-button>
                                    <mat-radio-button value="no">No</mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </div>
                    </div>
                    
                </div>
              </div>
            <div class="row" style="margin-top:30px">
                <div class="col-md-8 p1">
                    <label></label>
                    <div class="required-field">Upload the Observations document</div>
    
                </div>
                    <div class="rows">
                        <!-- <img class="img-responsive" src="assets/fileupload.png"> -->

                        <input type="file" style="width: 232px;margin-left: 6%;cursor: pointer;" id="addr_proof" name="addr_proof" (change)="onFileChanged($event)"/>
                    <div class="faTrash">
                    <fa-icon [icon]="faTrash" class="dismiss-icon" (click)="dismissIconClick('addr_proof')"></fa-icon>
                </div>
            </div>
            </div>
            <br>
            <div class="required-field p1 mb-2">Operative Portions of the Report</div>
             <textarea class="form-controll" rows="5" cols="200" placeholder="Enter Comments/Observations/Recommendations(if any)" name="comment" [(ngModel)]="comment"></textarea> 
            <br>
            <div class="status-btn" fxLayout="row wrap" fxLayoutGap="10px" fxLayoutAlign="space-between">
                 <div>
                    <div class="col-sm-12 offset-sm-10" >
                   <button class="scrutiny-user-btn doubtful-btn" (click)="updateApplicantStatus('observations_uploaded')">Observations Noted & Uploaded</button>
                 </div>
                </div>
            </div>
   
    <app-footer class="col-md-10 offset-sm-3" ></app-footer>
    </div>
    