import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { FormGroup, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { JccnoEncodeDecodeService } from 'src/services/jccno-encode-decode/jccno-encode-decode.service';
import { UserService } from 'src/services/user-service/user.service';
import { ApplicationService } from 'src/services/application.service';
import { StrorageService } from 'src/services/strorage.service';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-scrutiny-dashboard',
  templateUrl: './scrutiny-dashboard.component.html',
  styleUrls: ['./scrutiny-dashboard.component.scss']
})
export class ScrutinyDashboardComponent implements OnInit {

  @Input() color: string;
  public displayedColumns: string[] = ['sn','applicationNo'];

  public dataSource = new MatTableDataSource();

  public pageSize = 5;
  public currentPage = 0;
  public totalSize = 0;

  public dateFilters = [
    {
      "label":'Pre 2009',
      "subtitle":'(Pre 31 Dec 2008)',
      "tag":"pre_2009",
      "color":"orange",
      'active':false,
      'count': 0,
      "id":1
    },
    {
      "label":'2009 - 2014',
      "subtitle":'(1 Jan 2009 to 31 Dec 2014)',
      "tag":"2009_2014",
      "color":"blue",
      "active":false,
      'count': 0,
      "id":2
    },
    {
      "label":'2015- 3 Aug 2018',
      "subtitle":'(1 Jan 2015 to 3 Aug 2018)',
      "tag":"2015_3_aug_2018",
      "color":"#8a8a5c",
      "active":false,
      'count': 0,
      "id":3
    },
    {
      "label":'Post Aug 4-2018\n',
      "subtitle":'(4 Aug 2018 Onwards)',
      "tag":"post_4_aug_2018",
      "color":"brown",
      'count': 0,
      "active":false,
      "id":4
    }
  ];

  public approvedFilters = [
    {
      "label":'Plan Approved',
      "tag":"plan_approved",
      "color":"green",
      'active':false
    },
    {
      "label":'Plan Not Approved',
      "tag":"plan_not_approved",
      "color":"red",
      "active":false
    },
    {
      "label":'N/A',
      "tag":"na",
      "color":"lightgray",
      "active":false
    }
  ];
  public userName = "firstName";
  public villageName="Villagename";
  public show;
  public faSync = faSync;
  public isRefreshing = false;
  public isScrutinyUser;
  public isDateFilterSelected = false;
  public isApplicationSelected=false;
  public selectedButton=false;
  
  public applicants:any[]=[];
  public buttonsTexts:Array<string> = ['First button'];
  enableEdit: boolean = false;
  public isLoading = false;
  public colors=['#FF9633','#11C4E8','#F2FE2E','#CE8E05'];

status = 'Enable';
   
   myGroup = new FormGroup({
    applicationNo: new FormControl()
  
  });
  options: any[] = [];
  filters :any[] = [];
  villageId: any;
  village: any;
  selectedVillages: any [];
  selectapp:boolean=false;
  selectedDateFilter: any;
  selectedPlanApproveFilter: any;
  public user:any;
  public title: string;
  applicationNo: string;

  constructor(private router: Router, private route: ActivatedRoute,public dialog: MatDialog, 
    private jccEncodeDecoder: JccnoEncodeDecodeService,private userService: UserService,
    private applicationService: ApplicationService,private strorageService:StrorageService) { 
  
     
  }

  ngOnInit(): void {
    this.user=this.strorageService.getUser();
    if(!this.user['villageDTO']) {
      this.village=this.user['villageDTO'];
      this.villageName=this.village['name']
      this.strorageService.clear();
      this.router.navigate(['/scrutiny-login']);
      return;
    }
    this.village=this.user['villageDTO'];
    this.villageId=this.village['id'];
    this.getApplicants();
  }

  ngAfterViewInit() {
    
  }

  public getApplicantPaginatorData(event: PageEvent) {
    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getApplicants();
  }

getApplicants() {
    this.applicationService.getScrutinyApplicants(this.currentPage, this.pageSize,this.applicationNo,this.villageId).subscribe(res =>{
      console.log(res)
      this.refreshDataTable(res);
    });
}

private refreshDataTable = (res: any) => {
  this.totalSize = res.totalRecord;
  this.dataSource.data = res.data;
  console.log(res);
  this.dataSource._updateChangeSubscription();
}

searchApplicant(){
  this.applicationService.getScrutinyApplicants(this.currentPage, this.pageSize,this.applicationNo,this.villageId).subscribe(res =>{
    this.refreshDataTable(res);
   this.show=true;
  });     
}
onClear() {
  this.applicationNo = null;
  this.getApplicants();
}
 onPreviewClick = (applicantId: any) => {
   this.router.navigate(['/scrutiny-preview'], {queryParams:{application_id:applicantId}});
   }
  
refreshClick = () => {
  let currentComponent = this;
  this.isRefreshing = true;
  setTimeout(()=>{
    currentComponent.isRefreshing = false;
  }, 3000)
  this.applicationService.getApplicants();
 
}
public showActivityLog = () => {
  this.router.navigate(['/activity-log']);
  }
}
