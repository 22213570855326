import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { faTimes, faCheck, faTrash } from '@fortawesome/free-solid-svg-icons';
import { ApplicationService } from 'src/services/application.service';
import { WorkbenchCommentDialogComponent } from '../../field-visit-user/field-visit-workbench/workbench-comment-dialog/workbench-comment-dialog.component';
import { ConfirmDialogService } from '../../shared/confirm-dialog/confirm-dialog.service';
import { JccnoEncodeDecodeService } from 'src/services/jccno-encode-decode/jccno-encode-decode.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-leadership-users-workbench',
  templateUrl: './leadership-users-workbench.component.html',
  styleUrls: ['./leadership-users-workbench.component.scss']
})
export class LeadershipUsersWorkbenchComponent implements OnInit {
  data: any;
  enclosure: any;
  application_id: any;
  applicationData;
  selectedPanchayat = '';
  message:string;
  comment:string;
  status:string;
  public faTimes = faTimes;
  public isLoading=false;
  selectedVillages: any;
  public commentTypes: any = [];
  public fielVisitCommentTypes:any =[];
  public statusList: any = [];
    public title: any;
    approved_filter: any;
    public disable:boolean=true;
  createdAt: any;
  date: any;
  fieldVisitStatus: any;
  selectedLandType: any=[];
  public files: any = [];
  private applicantId: any;

  public faCheck = faCheck;


  public faTrash = faTrash
  applicationType: string;
  //dialogRef: MatDialogRef<AdjudicationOutOfBoundsDialogboxComponent>;

  constructor(private applicationService: ApplicationService,private route: ActivatedRoute, 
    private _snackBar: MatSnackBar, private router: Router,
    private workbenchDialogService: WorkbenchCommentDialogComponent, @Inject(DOCUMENT) private document,
    private dialog: MatDialog,private jccEncodeDecoder: JccnoEncodeDecodeService,
    private confirmDialogService: ConfirmDialogService, ) {
      this.application_id = this.route.snapshot.queryParams['application_id'];
     }

  ngOnInit(): void {
    this._fetchDataByApplicationNo();
  }
  private _fetchDataByApplicationNo() {
    this.applicationService.fetchApplicantById(this.application_id).subscribe(res=>{
      console.log("app",res)
      this.applicationData = res;
      console.log("title", this.title);
      this.fetchAllPanchayats();
      if(this.applicationData.activeStatus) {
        this.selectedLandType=this.applicationData.activeStatus.landTypeVOList;
      }
      let applicationType = this.applicationData.applicantType;
      if(applicationType == 'helpdesk_user') {
        this.applicationType = 'HD';
      }else{
        this.applicationType = 'Online';
      }
      this.fetchAllCommentTypes();
      this.fetchAllFieldVisitCommentTypes();
     })
     
}
close() {
  this.selectedVillages=this.route.snapshot.queryParams['village'];
  this.router.navigate(['/pi-user-dashboard']);
   
}
cancelClick(){
  this.close();
}

private fetchAllPanchayats(){
  this.applicationService.fetchAllPanchayats().subscribe(res=>{
    if(this.applicationData &&  this.applicationData.panchayat && res){
      this.selectedPanchayat = res[this.applicationData.panchayat-1]?.name;
    }
  })
}
private fetchAllCommentTypes(){
  this.applicationService.getAllLandTypes().subscribe(res=>{
    this.commentTypes = res.data;
    if(!this.applicationData.scrutinyStatus) {
      return;
    }
    let selectedCommentTypes = this.applicationData.scrutinyStatus.landTypeVOList;
    for(let i = 0;i < this.commentTypes.length;i++) {
      let isChecked = false;
      for(let j = 0;j < selectedCommentTypes.length;j++) {
          if(this.commentTypes[i]['id'] == selectedCommentTypes[j]['id']) {
              isChecked = true;
              break;
          }
      }
      this.commentTypes[i].checked = isChecked;
    }
  })
}
private fetchAllFieldVisitCommentTypes(){
  this.applicationService.getAllLandTypes().subscribe(res=>{
    this.fielVisitCommentTypes = res.data;
    if(this.applicationData.fieldVisitStatus){
    let selectedCommentTypes = this.applicationData.fieldVisitStatus.landTypeVOList;
    for(let i = 0;i < this.fielVisitCommentTypes.length;i++) {
      let isChecked = false;
      for(let j = 0;j < selectedCommentTypes.length;j++) {
          if(this.fielVisitCommentTypes[i]['id'] == selectedCommentTypes[j]['id']) {
              isChecked = true;
              break;
          }
      }
      this.fielVisitCommentTypes[i].checked = isChecked;
    }
  }
  })

}


encloPage() {
  let fullURL = location.protocol+'//'+location.hostname+(location.port ? ':'+location.port: '');
  window.open(fullURL+'#/preview-enclosure?application_id='+this.application_id, '_blank');
}

clickBack(){
  window.history.back();
}

updateRecordObservations =(id:any,applicantName:any) =>{
  this.router.navigate(['/leadership-users-judges-observation-workbench'], {queryParams:{application_id:id, applicant_name:applicantName}});
}

  openSnackbar = (message: string, action?: string) => {
    if(!action) {
      action = "Dismiss";
    }
    this._snackBar.open(message, action);
  }
  onFileChanged = (event: any) => {
    const file = {};
    file['name'] = event.target.name;
    file['data'] = event.target.files[0];
    this.files.push(file);
  }

  dismissIconClick = (id: any) => {
    (<HTMLInputElement> this.document.getElementById(id)).value = null;
       if(!this.files || this.files.length <= 0){
         return;
       }
    this.files.map((row, index) =>{
       if(row.name == id){
        this.files.splice(index, 1);
       }
    })
    }
}
