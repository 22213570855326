<div class="mainpage">
    <div fxLayout="row" >
    <mat-card *ngIf="data" style="padding-top: 20px !important">
    
    <div class="col-md-12 col-sm-12 details-area">
        <div class="col-md-2 col-sm-2"><mat-icon (click)="printPage()" class="print-icon">print</mat-icon></div>
        <div class="col-md-10 col-sm-10" style="margin-left: 50%">       
            <button class="btn pre-btn" (click)="onPreviewClick()" style="margin-right: 5px">Preview</button>
            <button class="btn pre-btn" (click)="goDetailsPage(data)" *ngIf="isEnclosureShown" style="margin-right: 5px">Enclosure</button>
            <button class="btn pre-btn one-time-editable" (click)="activateOneTimeEdit()" *ngIf="showOneTimeEditButton" style="margin-right: 5px">One-Time Edit</button>
            <button class="btn pre-btn save-changes" (click)="closeOneTimeEdit()" *ngIf="showSaveChangesBtn">Save Changes</button>
        </div>
    </div>
        <div class="row details-area">
            <div>
                <label class="info-key">Application ID :</label>
                <label class="info-value">{{data ? data.applicationId : ''}}</label>
            </div>
            <div>
                <label class="info-key"> Registration & Application No :</label>
                <label class="info-value">{{data ? data.applicationNo : ''}}</label>
            </div>
            <div>
                <label class="info-key">Applicant Name :</label> 
                <label class="info-value">{{data ? data.applicantName : ''}}</label> 
            </div>
            <div>
                <label class="info-key">Mobile No :</label> 
                <label class="info-value">{{data ? data.mobileNumber : ''}}</label> 
            </div>
            <div>
                <label class="info-key">Village  :</label> 
                <label class="info-value">{{data ? data.siteDetailsVillageVO.name : ''}}</label> 
            </div>
            
            <div>
                <label class="info-key">Survey No :</label> 
                <label class="info-value">{{data ? data.surveyMapperVO.surveyNo : ''}}</label> 
            </div>
            <div>
                <label class="info-key">Survey No /Hissa:</label> 
                <label class="info-value">{{data ? data.basicDetailsSurveyNoHissa : ''}}</label> 
            </div>
            <div>
                <label class="info-key">Site No :</label>  
                <label class="info-value">{{data ? data.siteNo : ''}}</label>
            </div>
           
        </div>
        <div class="row">
            <div class="checkbox-grid">
                <div *ngFor="let enclosure of enclosures; let i = index;" class="checkbox">
                    <mat-checkbox [checked]="enclosure.link != null" disabled><span class="acko-name">{{i+1}}. {{enclosure.documentName}}</span></mat-checkbox>
                </div>
            </div>    
        </div>
        <div *ngIf=partlyNotifiedSurveyNo>
            <div style="font-size: 18px;font-family:'Times New Roman';"> <b>The said Property is located on Partially Notified Sy.No. Hence field visit is mandated.</b></div>
        </div>  
        <div class="row btn-row">
                <div class="col-sm-2 offset-sm-9">
                    <button class="btn btn-next" (click)="onDone()">Done</button>
                </div>
        </div>
         
    </mat-card>
  
 </div>
</div>

<!-- section to take print out-->
<div style="margin-left: 10%; margin-right: 4%;" id="print-section" class="printpage" #printData>
    <div class="row" style="padding-bottom: 10pt;border-bottom: solid black 1pt;">
        <div class="col-md-2">
            <img src="../assets/print-logo.jpeg" style="max-width:150px;margin-left: 0%;margin-top: -25%;" />
        </div>
        <div class="col-md-8">
            <style>@import url(http://fonts.googleapis.com/earlyaccess/notosanskannada.css);
                body { font-family: "Nirmala UI", sans-serif; font-size: 19.0px; line-height: 1.11em; }</style>
            <div class="title" style="font-size: 30px;text-align: center;font-family: Tunga;"><b> ಬೆಂಗಳೂರು ಅಭಿವೃದ್ಧಿ ಪ್ರಾಧಿಕಾರ </b></div>
            <div style="font-size: 35px;text-align: center;margin-top: 8%;font-family: 'Times New Roman';">
                <b>Bangalore Development Authority</b>
            </div>
        </div>
    </div>
    <div class="row" style="padding-bottom: 10pt;border-bottom: solid black 1pt;">
        <div class="col-md-12">
            <div class="title" style="font-size:18px; text-align: center;margin-top: 1%;font-family:'Times New Roman', Nirmala UI;"><b>ಡಾ|| ಕೆ. ಶಿವರಾಮ ಕಾರಂತ್  ಬಡಾವಣೆಯ ರೆವೆನ್ಯೂ ನಿವೇಶನಕ್ಕೆ ಬದಲಿ ನಿವೇಶನದ ಅರ್ಜಿ ಸ್ವೀಕೃತಿ ಪ್ರತಿ </b></div>
        </div>
        <div class="col-md-12">
            <div class="title" style="font-size: 18px;text-align: center;font-family:'Times New Roman', Nirmala UI;">(ಮಾನ್ಯ ಸರ್ವೋಚ್ಛ ನ್ಯಾಯಾಲಯವು ಎಂ ಐ ಎಸ ಸಿ ಸಂಖ್ಯೆಯು  1614-1616 / 2019  </div>
            <div class="title" style="font-size: 18px;text-align: center;padding-bottom: 1pt;font-family:'Times New Roman', Nirmala UI;"> ಸಿವಿಲ್ ಅಪೀಲ್ ಸಂಖ್ಯೆ :  7661-7663 / 2018 ದಿನಾಂಕ 03-08-2018 ರಂದು ಮಾಡಿರುವ ಆದೇಶದ ಅನುಸಾರ )</div>
        </div>
    </div>
    <br>
    
        <div style="text-align: center; background-color: #3583f6 !important;font: message-box;padding: 14px 40px;border-radius: 12px;
        -webkit-print-color-adjust: exact; color:white !important;margin-inline: 25%;font-size: 22px;font-family:'Times New Roman', Nirmala UI;">ಸ್ವೀಕೃತಿ /ACKNOWLEDGEMENT </div>
        <br>
    <div class="row">
        <div class="col-md-6">
            <div style="border-style: solid;
                border-color: black;
                border-width: 1pt 1pt 1pt 1pt;
                border-radius: 5pt;padding: 3pt 3pt 3pt 3pt;
                font-size: 20px;font-family:'Times New Roman', Nirmala UI;"> Application Id:&nbsp;&nbsp;<b>{{data ? data.applicationId : ''}}</b></div><br>
        </div>
        <div class="col-md-6">
                <div style="border-style: solid;
                border-color: black;
                border-width: 1pt 1pt 1pt 1pt;
                border-radius: 5pt;
                padding: 3pt 3pt 3pt 3pt;
                font-size: 20px;font-family:'Times New Roman', Nirmala UI;"> Registration No. & Application No:&nbsp;&nbsp;<b>{{data ? data.applicationNo : ''}}</b></div>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col-md-6">
            <div style="width: 100%;font-size: 15px;font-family:'Times New Roman', Nirmala UI;"><b>ಶ್ರೀಮತಿ/ಶ್ರೀ Smt/Sri:</b>&nbsp;&nbsp;<b>{{data ? data.applicantName : ''}}</b></div>
            <div style="border-bottom: solid rgb(2, 89, 250) 1pt;"></div>
        </div>
        <div class="col-md-6">
            <div  style="width:100%;font-size: 15px;font-family:'Times New Roman', Nirmala UI;"><b>ಗ್ರಾಮ/Village:</b> &nbsp;&nbsp;<b>{{data ? data.siteDetailsVillageVO.name : ''}}</b></div>
            <div style="border-bottom: solid rgb(2, 89, 250) 1pt;"></div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div style="width: 100%;font-size: 15px;font-family:'Times New Roman', Nirmala UI;"><b>ಸರ್ವೆ ನಂ. /Survey No:</b>&nbsp;&nbsp;<b>{{data ? data.surveyMapperVO.surveyNo : ''}}</b>
            <div style="border-bottom: solid rgb(2, 89, 250) 1pt;"></div></div>
        </div>
        <div class="col-md-6">
            <div style="width: 100%;font-size: 15px;font-family:'Times New Roman', Nirmala UI;"><b>ಸೈಟ್ ನಂ. /Site No:</b>&nbsp;&nbsp;<b>{{data ? data.siteNo : ''}}</b>
            <div style="border-bottom: solid rgb(2, 89, 250) 1pt;"></div></div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div style="width: 100%;font-size: 15px;font-family:'Times New Roman', Nirmala UI;"><b>ಸರ್ವೇ ನಂ./ Survey No ಹಿಸ್ಸಾ/ Hissa:</b>&nbsp;&nbsp;<b>{{data ? data.basicDetailsSurveyNoHissa : ''}}</b>
            <div style="border-bottom: solid rgb(2, 89, 250) 1pt;"></div></div>
        </div>
    </div>
    <div class="row" style="margin-top:18px;font-size: 15px;font-family:'Times New Roman', Nirmala UI;">
        <div style="width:100%;margin-left: 2%;">
            ರವರಿಂದ ಭರ್ತಿಮಾಡಿದ ಅರ್ಜಿಯನ್ನು ಸ್ವೀಕರಿಸಲಾಗಿದೆ. / Received filled Application.
        </div>
    </div>
    <div class="row" style="margin-top:25px;font-size: 18px;font-family:'Times New Roman', Nirmala UI;">
        <div style="text-align: center;width:100%;color:black;"><b>ಸ್ವೀಕೃತ ದಾಖಲೆಗಳು  /Received Enclosures</b>
            <div style="border-bottom: solid;color: black;
            border-top: outset;margin-inline: 23%;border-width: medium;"></div></div>
    </div>
    <div class="col-md-12">
        <div *ngFor="let enclosure of enclosuresPrintData; let i = index;" class="checkbox" style="margin-bottom: 5px;;font-size: 18px;font-family:'Times New Roman', Nirmala UI;">
            <div *ngIf="enclosure.active">
                <input type="checkbox"  checked="true" style="margin-right: 5px;height:20px !important;position:absolute"/><span style="margin-left:15px">{{enclosure.title}}</span>
            </div>
            <div *ngIf="!enclosure.active">
                <fa-icon [icon]="faTimes" style="color:red;margin-right: 5px"></fa-icon><span>{{enclosure.title}}</span>
            </div>
        </div>
    </div>
    <div style="font-size: 20px;font-family:'Times New Roman';"><b>The Property mentioned supra has been notified by BDA and therefore this application has been received & registered.</b></div>
    <br>
    <div *ngIf=partlyNotifiedSurveyNo>
        <div style="font-size: 20px;font-family:'Times New Roman';"><b>The said Property is located on Partially Notified Sy.No. Hence field visit is mandated.</b> </div>
    </div>
    
   <div style="margin-top: 60px;padding-left: 13px;;font-size: 20px;font-family:'Times New Roman';">
        <div style="font-family:'Times New Roman';">Receiving Date:</div>
        <div style="margin-left: 50%;margin-top: -2%;font-family:'Times New Roman';">Signature of Receiving Authority with seal</div>
    </div>
</div>

