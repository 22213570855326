<div class="title" style="font-size: large;text-align: center;"><h2><b>ಬ ೆಂಗಳೂರು ಅಭಿವೃದಿಧಿ ಪ್ರಾಧಿಕರರ </b></h2>
    </div>
    <div class="title" style="font-size: large;text-align: center;">
        <h1><b><u>Bangalore Development Authority</u></b></h1> 
        </div><br>
        <div class="title" style="font-size: small;text-align: center;"><b>ಡಾ|| ಕೆ. ಶಿವರಾಮ ಕಾರಂತ್ ಬಡಾವಣೆಯರೆವೆನ್ಯೂ ನಿವೇಶನಕೆೆ ಬದಲಿ ನಿವೇಶನದ ಅರ್ಜಿ ಸ್ವ ೀಕೃತಿ ಪ್ರ ತಿ</b>
        </div><br>
        
        <div class="title" style="font-size: medium;text-align: center;">(ಮಾನ್ಯ ಸರ್ವೋಚ್ಛ ನ್ಯಯ ಯಾಲಯವು ಎಂ ಐ ಎಸ್ ಸಿ ಸಂಖ್ಯಯ 1614-1616 / 2019  
        </div>
        <div class="title" style="font-size: medium;text-align: center;"> ಸಿವಿಲ್ ಅಪವಲ್ ಸಂಖ್ಯಯ: 7661-7663 / 2018 ರಲ್ಲಿ ಮಾಡಿರುವ ಆದೇಶದ ಅನುಸಾರ)</div>
        <!-- <mat-divider></mat-divider> -->
        <br>
        <div class="tack">
        <div class="btn pre-btn">ACKNOWLEDGEMENT</div>
        </div> 
        <br>
       <div fxLayout="row">
        <div class="oline"> Application Id:</div>
        <div class="title"> Registration No. & Application No: </div>
        </div>
        <br>
<br>
<div class="container"><h4><b>ಶಿರ ೀಮತಿ/ಶಿರ ೀ Smt/Sri</b></h4>
  <div class="line"><mat-divider></mat-divider></div>
  <div class="text mat-typography"></div>
  <div class="line"><mat-divider></mat-divider></div>
</div>

<div class="container"><h4><b>ಹಳ್ಳಿ /Village:</b> </h4>
  <div class="line"><mat-divider></mat-divider></div>
  <div class="text mat-typography"></div>
  <div class="line"><mat-divider></mat-divider></div>
</div>

<div class="container"><h4><b>ಸರ್ವೆ ನಂ. /Survey No.</b></h4>
  <div class="line"><mat-divider></mat-divider></div>
  <div class="text mat-typography"></div>
  <div class="line"><mat-divider></mat-divider></div>
</div>
<div class="container"><h4>ಸವೈಟ್ ನಂ. /Site No</h4>
    <div class="line"><mat-divider></mat-divider></div>
    <div class="text mat-typography"></div>
    <div class="line"><mat-divider></mat-divider></div>
  </div>
  <br>
  <div class="container">
    ರವರಂದ ಭರ್ತೋಮಾಡಿದ ಅರ್ಜೋಯನುು ಸಿವ ವಕರಸಲಾಗಿದೆ / Received filled Application.
  </div>
  <br>
  <div style="text-align: center;color: black;">
    <h3><u><b>ಸ್ವ ೀಕೃತದಾಖಲೆಗಳು /Received Enclosures</b></u></h3>
  </div>
  <!-- <div class="col-auto"> -->
  <div class="card col-sm-12 offset-md-3"  >
    <div class="checkbox-grid">
        <div *ngFor="let enclosure of enclosures; let i = index;" class="checkbox">
            <mat-checkbox [checked]="enclosure.link != null" ><span >{{enclosure.title}}</span></mat-checkbox>
        </div>
    </div>    
<!-- </div> -->
</div>

