import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { ConfirmDialogService } from 'src/app/shared/confirm-dialog/confirm-dialog.service';
import { ApiService } from 'src/services/api.service';
import { ApplicationService } from 'src/services/application.service';
import { StrorageService } from 'src/services/strorage.service';


@Component({
  selector: 'app-committee-datatable',
  templateUrl: './committee-datatable.component.html',
  styleUrls: ['./committee-datatable.component.scss']
})
export class CommitteeDatatableComponent implements OnInit {

  public dataSource = new MatTableDataSource();
 
  public displayedColumns: string[] = ['siNo','applicationId', 'applicantName', 'mobileNumber', 'comment', 'status', 'villageName', 'updatedBy', 'updatedAt', 'alternateSite', 'action' ];

  public faSync = faSync;
  public isRefreshing = false;
  public currentPage = 0;
  public show = false;
  public totalSize = 0;
  user: any;
  public pageSize =5;
  public cdInfoReportBatchName: any;
  public searchKeyword: any;
  public applicantDataList: any = [];
  applicationNo = null;
  village: any;
  villages : any[];
  villageId: any;
  selectedVillages: number;
  public totalApplicantCount: any = 0;
  applicantServiceApiCallObserver: any;
  private piUserLastActivity: any;
  notificationType: any;
  notificationHeading : any;
  statusCode: any;
  downloadUrl: any;

  constructor(private cdr:ChangeDetectorRef,private applicationService: ApplicationService,private router: Router,private confirmDialogService: ConfirmDialogService, private apiService:ApiService,
    private storageService: StrorageService, private route:ActivatedRoute) {
        this.user = this.storageService.getUser();
        this.notificationType = this.route.snapshot.queryParams['notificationType'];
        if(this.notificationType == 'OLD'){
          this.notificationHeading = 'Old Notifications';
        }
        else if(this.notificationType == 'NEW'){
          this.notificationHeading = 'New Notifications';
        }
        else{
          this.notificationHeading = 'All Notifications';
        }
        this.statusCode = this.route.snapshot.queryParams['statusCode'];
        this.villageId =  this.route.snapshot.queryParams['villageId'];
     }

  ngOnInit() {
    this.getApplicantData();
    this.fetchVillages();
  }


getApplicantData() {
    this.applicationService.getApplicantDataByStatus(this.currentPage, this.pageSize, this.notificationType, this.applicationNo, this.villageId,this.statusCode).subscribe(res=>{
      this.refreshReportBatchesDataTable(res);
    })  
  }

  downloadAllAsExcelsheet() {
    this.applicationService.getObservationDataExcelsheet().subscribe(res=>{
      this.downloadUrl = res.data;
      console.log(res);
      this.downloadFile(this.downloadUrl);
    })
  }

  onVillageChange(){
    this.getApplicantData();
  }

  formattDate = (date:any) =>{
    let pipe = new DatePipe('en-IN');
    return pipe.transform(date, 'dd/MM/yyyy');
  }

  onClear(){
    this.applicationNo=null;
    this.villageId="";
    this.getApplicantData();
    }

  fetchVillages() {
    this.applicationService.fetchAllVillages().subscribe(res=>{
      this.villages = res;
      this.villages.unshift({
        "id":"",
        "name":"All"
       })
    });
  }

 goBack(): void {
  window.history.back();
 }

 refreshClick(): void {
  this.currentPage = 0;
  this.pageSize = 10;
  this.applicationNo=null;
  this.villageId="";
  this.getApplicantData();
}

 getApplicantPaginationData(event: PageEvent) {
  this.currentPage = event.pageIndex;
  this.pageSize = event.pageSize;
  this.getApplicantData();
}


 refreshReportBatchesDataTable = (res: any) => {
  this.dataSource.data = res.data;
  this.totalSize = res.totalRecord;
  this.currentPage = (res.currentPage - 1);
  this.dataSource._updateChangeSubscription();
  this.cdr.detectChanges();
}

onPreviewClick(applicantId: any){
  this.router.navigate(['/leadership-users-activity-log-view'], {queryParams:{application_id:applicantId}});
 }

 downloadFile(url: string) {
  let link = document.createElement('a');
  link.href = url;
  link.download = url.substring(url.lastIndexOf('/') + 1);
  link.style.display = 'none';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

}
