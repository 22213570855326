import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationService } from 'src/services/application.service';

@Component({
  selector: 'app-leadership-users-activity-log-view',
  templateUrl: './leadership-users-activity-log-view.component.html',
  styleUrls: ['./leadership-users-activity-log-view.component.scss']
})
export class LeadershipUsersActivityLogViewComponent implements OnInit {

  application_id: any;
  applicationData : any;
  selectedPanchayat = '';
  message:string;
  comment:string;
  status:string;
  public questions:any;
  public subQuestions:any;
  public observationId:any;
  
  public isLoading=false;
  selectedVillages: any;
  public commentTypes: any = [];
  public observations: any ;
  public scrutinyLandTypes:any=[];
  public fieldVisitStatusList: any = [];
    public title: any;
    approved_filter: any;
    public disable:boolean=true;
  createdAt: any;
  date: any;
  refreshDataTable: any;
  currentPage: any;
  pageSize: any;
  fieldVisitData: any;
  fieldVisitDetails: any=[];
  selectedLandType: any=[];
  selectedScrutinyLandTypes:any=[];
  observationValues: any;
  observationQuestions: any = [];
  @Input() data: any = {};
  applicationType: string;
   

  constructor(private applicationService: ApplicationService,private route: ActivatedRoute, 
    private _snackBar: MatSnackBar, private router: Router) { 
      this.application_id = this.route.snapshot.queryParams['application_id'];
      console.log("jcc", this.application_id)}

      ngOnInit(): void {
        this.getAdjudicationActivityLogDetails();
       
      }
    
      getAdjudicationActivityLogDetails(){
       this.applicationService.fetchApplicantById(this.application_id).subscribe(res=>{
         console.log("field",res);
         this.applicationData = res;
         this.comment = res.comment;
         if(this.applicationData.fieldVisitStatus) {
            this.selectedLandType=this.applicationData.fieldVisitStatus.landTypeVOList;
            console.log(this.selectedLandType);
          
         }
         if(this.applicationData.scrutinyStatus) {
            this.selectedScrutinyLandTypes = this.applicationData.scrutinyStatus.landTypeVOList;
         }
          let applicationType = this.applicationData.applicantType;
          if(applicationType == 'helpdesk_user') {
            this.applicationType = 'HD';
          }else{
            this.applicationType = 'Online';
          }
         this.fetchAllFieldVistLandTypes(this.selectedLandType);
         this.fetchAllScrutinyLandTypes();
      })
    }
    private fetchAllFieldVistLandTypes(selectedCommentTypes: any){
     this.applicationService.getAllLandTypes().subscribe(response=>{
       let res = response.data;
       for(let i = 0;i < res.length;i++) {
         res[i].checked = false;
         for(let idx = 0;idx < selectedCommentTypes.length;idx++) {
           if(selectedCommentTypes[idx]['id'] == res[i]['id']) {
             res[i].checked = true;
             break;
           }
         }
         this.commentTypes.push(res[i]);
       }
     })
   }
   private fetchAllScrutinyLandTypes(){
    this.applicationService.getAllLandTypes().subscribe(response=>{
      let res = response.data;
      for(let i = 0;i < res.length;i++) {
        res[i].checked = false;
        for(let idx = 0;idx < this.selectedScrutinyLandTypes.length;idx++) {
          if(this.selectedScrutinyLandTypes[idx]['id'] == res[i]['id']) {
            res[i].checked = true;
            break;
          }
        }
        this.scrutinyLandTypes.push(res[i]);
      }
    })
  }
 
      clickBack(){
        window.history.back();
      }

  encloPage() {
    let fullURL = location.protocol+'//'+location.hostname+(location.port ? ':'+location.port: '');
    window.open(fullURL+'#/preview-enclosure?application_id='+this.application_id, '_blank');
  }

}
