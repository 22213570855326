import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ApplicationService } from 'src/services/application.service';
import { UserService } from 'src/services/user-service/user.service';

@Component({
  selector: 'app-filter-option',
  templateUrl: './filter-option.component.html',
  styleUrls: ['./filter-option.component.scss']
})
export class FilterOptionComponent implements OnInit {
  @Input() data: any; 
  @Input() color: string;
  @Input() type: string;
  @Input() selectedId: any;
  @Output() selectOption = new EventEmitter<number>();
  @Input() notification: any;

  public applicantCount;
  isCommitteeUser: boolean;
  
  constructor(private applicationService : ApplicationService, private userService: UserService) { }

  ngOnInit(): void {
    console.log(this.notification);
    this.isCommitteeUser = this.userService.getUserType()== this.userService.COMITTEE_USER;
    if(this.type == 'village'){
      if(this.notification != null) {
        this.fetchApplicantCountByVillageAndNotificationType(this.notification);
      }else {
        this.fetchApplicantCountByVillage();
      }
    }
        else if(this.type == 'panchayat'){
          if(this.notification != null) {
            this.fetchApplicantCountByPanchayatAndNotificationType(this.notification);
          }else {
            this.fetchApplicantCountByPanchayat();
          }
    }
  }

fetchApplicantCountByVillage(){
    this.applicationService.fetchApplicantCountByVillage(this.data.id).subscribe(res =>{
      this.applicantCount = res;
    })
}

fetchApplicantCountByVillageAndNotificationType(notificationType: any){
  this.applicationService.fetchApplicantCountByVillageAndNotificationType(notificationType,this.data.id).subscribe(res =>{
    this.applicantCount = res;
  })
}


fetchApplicantCountByPanchayat(){
  this.applicationService.fetchApplicantCountByPanchayat(this.data.id).subscribe(res =>{
    this.applicantCount = res;
  })
}

fetchApplicantCountByPanchayatAndNotificationType(notification: any){
  this.applicationService.fetchApplicantCountByPanchayatAndNotificationType(notification,this.data.id).subscribe(res =>{
    this.applicantCount = res;
  })
}
  clickOption(){
    this.selectOption.emit(this.data.id);
  }

}
