<div class="content-area col-sm-10 offset-sm-1">
    <div class="headerpage col-sm-12">
        <app-top-bar selectedTab="home"></app-top-bar>
        <app-header></app-header>
        <div fxLayout="row">
            <div class="row header">
       
         <div class="col-sm-6">
          <h1 class="display-4 header-text">Application Form - <span class="heading-title"> {{heading}} </span></h1>
          <label class="info-title" >Applications without Documents will not be Considered.</label>
          <label class="info-message"><b>PLEASE UPLOAD RELEVANT DOCUMENTS.</b></label>
        </div>
        <div class="col-sm-5 offset-sm-1 user-details">
            <div>
                <div class="row key-text">
                    Name
                 </div>
                 <div class="row value-text">
                     {{applicantName ? applicantName : 'Not Created' }}
                 </div>
            </div>
            <div>
                <div class="row key-text">
                    Application ID
               </div>
               <div class="row value-text">
                   {{applicationId ? applicationId : 'Not Created' }}
               </div>
            </div>
        </div>
        </div>
        </div>
        </div>
        <div>
            <label style="font-size: 22px;font-family: Montserrat;font-weight: bold;">Channel : {{applicationType}}</label>
        </div>
    </div>
        <div class="row w-100 d-flex justify-content-between">
            <div class="col-sm-5 mx-5">
                <div class="row">
                    <div class="col-sm-3 d-flex justify-content-end" style="font-size: 17px;">
                        Status :
                    </div>
                    <div class="col-sm-5 p-0 value-texts">
                        <span style="color:#25D69C">{{status}}</span>
                    </div>
                </div>
                </div>
                <div class="col-sm-4" style="font-size:large;">ನಿವೇಶನದ ಅಳತೆ/ Site Dimension : 9 * 12 ಮೀ./mtrs</div>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
            <div *ngFor="let menu of menus" (click)="openSelectedMenu(menu)">
                <mat-card [class.form-selected-card]="menu.active" 
                [class.form-not-selected-card]="!menu.active">
                        {{menu.name}}
                </mat-card>
            </div>
        </div>
        
        <!-- new code-->
        <div *ngIf="selectedMenu.showMatCard">
            <mat-card class="jcc-card form-card">
                <div class="form-main-title">
                    {{selectedMenu.name}}
                </div>
                <div fxLayout="row">
                    <app-basic-info *ngIf="selectedMenu['code'] == 'basic_details'" fxFlex="100%" (newApplicantCallback)="updateUI($event)" (nextScreenCallback)="nextScreenCallback($event)"></app-basic-info>
                    <app-personal-info *ngIf="selectedMenu['code'] == 'personal_info'" fxFlex="100%" (nextScreenCallback)="nextScreenCallback($event)"></app-personal-info>
                    <app-property-details *ngIf="selectedMenu['code'] == 'property_details'" fxFlex="100%" (nextScreenCallback)="nextScreenCallback($event)"></app-property-details>
                    <app-details-of-approval *ngIf="selectedMenu['code'] == 'miscellaneous'" fxFlex="100%" (nextScreenCallback)="nextScreenCallback($event)"></app-details-of-approval>
                </div>
            </mat-card>
        </div>
        <div fxLayout="row" *ngIf="!selectedMenu.showMatCard">
            <app-acknowledgement *ngIf="selectedMenu['code'] == 'acknowledgement'" fxFlex="100%" (oneTimeEditCallback)="oneTimeEditCallback($event)" (saveChangesCallback)="nextScreenCallback($event)"></app-acknowledgement>
            <app-upload-enclosure *ngIf="selectedMenu['code'] == 'enclosures'" (nextScreenCallback)="nextScreenCallback($event)"></app-upload-enclosure>
        </div>
        <!-- end -->
    <app-footer></app-footer>