<div class ="container">
    <div class="row content-area"><br>
        <br>
        <br>
        <div class="col-sm-12 title">
            JUDGE’S OBSERVATION WORKBENCH
        </div>
        
        <div class="label col-sm-10"  *ngIf = "applicationData">
      
            <div class ="col-sm-12 text-title">DSKL NO:{{applicationData.applicationId}}<br>{{title}}</div>
          
            <div >
                <button (click)="clickBack()"class="back-link">Back</button>
             </div>
           <div>
            <button  (click) ="encloPage()" class="enclosure-link">Enclosure </button> 
        </div>   
     <br>
    <br>
    <br>
    <div>
        <label style="font-size: 21px;font-family: Montserrat;font-weight: bold;">Channel : {{applicationType}}</label>
    </div> 
    <div class="sub-title">
        Basic Info
     </div>
     <div class="row" >
         <div class="col-sm-8 " >
             <label >Registration & Application No</label>
         </div>
         <div class="col-sm-4">
             <label >{{applicationData.applicationNo}}</label>
           </div>
     </div>
     <div class="row" >
         <div class="col-sm-8 " >
             <label >Date Of Registration</label>
         </div>
         <div class="col-sm-4">
             <label >{{applicationData.registrationDate | amLocale:'en' | amDateFormat:'DD-MM-YYYY'}}</label>
           </div>
     </div>
     <div class="row" >
         <div class="col-sm-8 " >
             <label >Regn Fee</label>
         </div>
         <div class="col-sm-4">
             <label >{{applicationData.registrationFees}}</label>
           </div>
     </div>
     <div class="row" >
         <div class="col-sm-8 " >
             <label >Name Of The Applicant</label>
         </div>
         <div class="col-sm-4">
             <label >{{applicationData.applicantName}}</label>
           </div>
     </div>
     <div class="row" >
         <div class="col-sm-8 " >
             <label >Father’s/Husband’s Name</label>
         </div>
         <div class="col-sm-4">
             <label >{{ applicationData.fatherName}}</label>
           </div>
     </div>
     <div class="row" >
         <div class="col-sm-8 " >
             <label >Mobile Number</label>
         </div>
         <div class="col-sm-4">
             <label >{{applicationData.mobileNumber}}</label>
           </div>
     </div>
     <div class="row" >
         <div class="col-sm-8 " >
             <label >Email</label>
         </div>
         <div class="col-sm-4">
             <label >{{applicationData.email}}</label>
           </div>
     </div>
     <div class="row" >
         <div class="col-sm-8 " >
             <label >Postal Address of the Applicant</label>
         </div>
         <div class="col-sm-4">
             <label >{{applicationData.applicantAddress}}</label>
           </div>
     </div>
     <div class="row" >
         <div class="col-sm-8 " >
             <label >City</label>
         </div>
         <div class="col-sm-4">
             <label >{{applicationData.applicantCity}}</label>
           </div>
     </div>
     <div class="row" >
         <div class="col-sm-8 " >
             <label >State/Province</label>
         </div>
         <div class="col-sm-4">
             <label >{{applicationData.state}}</label>
           </div>
     </div>
     <div class="row" >
         <div class="col-sm-8 " >
             <label >Country</label>
         </div>
         <div class="col-sm-4">
             <label >{{applicationData.country}}</label>
           </div>
     </div>
     <div class="row" >
         <div class="col-sm-8 " >
             <label >Pin Code</label>
         </div>
         <div class="col-sm-4">
             <label >{{applicationData.pincode}}</label>
           </div>
     </div>
     <div class="row" >
         <div class="col-sm-8 " >
             <label >Village where your revenue site is located</label>
         </div>
         <div class="col-sm-4">
             <label >{{ applicationData.siteDetailsVillageVO.name}}</label>
           </div>
     </div>
     <div class="row" >
         <div class="col-sm-8 " >
             <label >Survey Number</label>
         </div>
         <div class="col-sm-4">
             <label >{{applicationData.surveyMapperVO.surveyNo}}</label>
           </div>
     </div>
     <div class="row" >
         <div class="col-sm-8 " >
             <label >Survey Number/Hissa</label>
         </div>
         <div class="col-sm-4">
             <label >{{applicationData.basicDetailsSurveyNoHissa}}</label>
           </div>
     </div>
     <div class="row" >
         <div class="col-sm-8 " >
             <label >Site Number</label>
         </div>
         <div class="col-sm-4">
             <label >{{applicationData.siteNo}}</label>
             </div>
     </div>
     <div class="sub-title">
         Personal Info
      </div>
     <div class="row" >
         <div class="col-sm-8 " >
             <label >Date Of Birth</label>
         </div>
         <div class="col-sm-4">
             <label >{{applicationData.applicantDob ? (applicationData.applicantDob | amLocale:'en' | amDateFormat:'DD-MM-YYYY') : ''}}</label>
           </div>
     </div>
     <div class="row" >
         <div class="col-sm-8 " >
             <label >Gender</label>
         </div>
         <div class="col-sm-4">
             <label >{{applicationData.gender}}</label>
           </div>
     </div>
     <div class="row" >
         <div class="col-sm-8 " >
             <label >Age</label>
         </div>
         <div class="col-sm-4">
             <label >{{applicationData.applicantAge}}</label>
           </div>
     </div>
     <div class="row" >
         <div class="col-sm-8 " >
             <label >Occupation</label>
         </div>
         <div class="col-sm-4">
             <label >{{applicationData.applicantOccupation}}</label>
           </div>
     </div>
     <div class="row" >
         <div class="col-sm-8 " >
             <label >Marital Status</label>
         </div>
         <div class="col-sm-4">
             <label >{{applicationData.maritalStatus}}</label>
           </div>
     </div>
     <div class="row" >
         <div class="col-sm-8 " >
             <label >Aadhar Number</label>
         </div>
         <div class="col-sm-4">
             <label >{{applicationData.aadharNo}}</label>
         </div>
     </div>
     <div class="row" >
         <div class="col-sm-8 " >
             <label >Pan Number</label>
         </div>
         <div class="col-sm-4">
             <label >{{applicationData.panNo}}</label>
           </div>
     </div>
     <div class="row" >
         <div class="col-sm-8 " >
             <label >Annual Income</label>
         </div>
         <div class="col-sm-4">
             <label >{{ applicationData.annualIncome}}</label>
           </div>
     </div>
     <div class="row" >
         <div class="col-sm-8 " >
             <label >Are you Residing in Karnataka for not les than 15 years prior to the date of Registration for Site ?</label>
         </div>
         <div class="col-sm-4">
             <label >{{ applicationData.regForSite == null ? '' : (applicationData.regForSite) ? 'Yes' : 'No'}}</label>
           </div>
     </div>
     <div class="row" >
         <div class="col-sm-8 " >
             <label>Have you or your dependents in the family been allotted site or house by BDA or in the Bangalore Metropolitan area jurisdiction by any Co-operative Society?</label>
         </div>
         <div class="col-sm-4">
             <label >{{ applicationData.isHouseAllotedByBda == null ? '' : (applicationData.isHouseAllotedByBda) ? 'Yes' : 'No'}}</label>
           </div>
     </div>
     <div class="row" >
         <div class="col-sm-8 " >
             <label >Have you or your dependents in the family been allotted site or house in any region of Karnataka state by any Urban Development or Karnataka Housing Board?</label>
         </div>
         <div class="col-sm-4">
             <label >{{applicationData.isHouseAllotedByUrban == null ? '' : (applicationData.isHouseAllotedByUrban) ? 'Yes' : 'No'}}</label>
           </div>
     </div>
     <div class="sub-title">
         Property Details
      </div>
      <div class="row" >
         <div class="col-sm-8 " >
             <label>Village where your revenue site is located</label>
         </div>
         <div class="col-sm-4">
             <label >{{ applicationData.siteDetailsVillageVO.name}}</label>
           </div>
     </div>
     <div class="row" >
         <div class="col-sm-8 " >
             <label >Survey Number</label>
         </div>
         <div class="col-sm-4">
             <label >{{applicationData.surveyMapperVO.surveyNo}}</label>
           </div>
     </div>
     <div class="row" >
         <div class="col-sm-8 " >
             <label >Survey Number/Hissa</label>
         </div>
         <div class="col-sm-4">
             <label >{{applicationData.basicDetailsSurveyNoHissa}}</label>
           </div>
     </div>
    
     <div class="row" >
         <div class="col-sm-8 " >
             <label >Site Number</label>
         </div>
         <div class="col-sm-4">
             <label >{{applicationData.siteNo}}</label>
             </div>
     </div>
     <div class="row" >
         <div class="col-sm-8 " >
             <label >Site Dimension</label>
         </div>
         <div class="col-sm-4">
             <label >{{applicationData.propertyDetailsSiteDimension}}</label>
           </div>
     </div>
     <div class="row" >
         <div class="col-sm-8 " >
             <label >Date of Registration of the Sale Deed</label>
         </div>
         <div class="col-sm-4">
             <label >{{applicationData.saledeedRegDate ? (applicationData.saledeedRegDate | amLocale:'en' | amDateFormat:'DD-MM-YYYY') : ''}}</label>
           </div>
     </div>
    
     <div class="row" >
         <div class="col-sm-8 " >
             <label >Does the Revenue site has a Khata issued by the concerned Panchayat?</label>
         </div>
         <div class="col-sm-4">
             <label >{{applicationData.isKhataIssuedByPanchayat == null ? '' : (applicationData.isKhataIssuedByPanchayat) ? 'Yes' : 'No'}}</label>
           </div>
     </div>
     <div class="row" >
         <div class="col-sm-8 " >
             <label >Panchayat Name</label>
         </div>
         <div class="col-sm-4">
             <label>{{applicationData.khataIssueByPanchayatVO ? applicationData.khataIssueByPanchayatVO.name : ''}}</label>
           </div>
     </div>
     <div class="row" >
         <div class="col-sm-8 " >
             <label >With reference to the Revenue site, is there a pending case in any court?</label>
         </div>
         <div class="col-sm-4">
             <label >{{applicationData.hasCasePending == null ? '' : (applicationData.hasCasePending) ? 'Yes' : 'No'}}</label>
           </div>
     </div>
     <div class="row" >
         <div class="col-sm-8 " >
             <label>Case No</label>
         </div>
         <div class="col-sm-4">
             <label >{{applicationData.caseNo}}</label>
         </div>
     </div>
     <div class="row" >
         <div class="col-sm-8 " >
             <label >Name of the Court</label>
         </div>
         <div class="col-sm-4">
             <label >{{applicationData.courtName}}</label>
           </div>
            </div>
            <!-- <div class="row"> -->
                <!-- <div class="col-md-8 p1">
                    <label></label>
                    <div class="required-field">Upload the Observations document</div>
    
                </div> -->
                    <!-- <div class="rows">
                        <img class="img-responsive" src="assets/fileupload.png">
                   
                    <input type="file" id="addr_proof" name="addr_proof" (change)="onFileChanged($event)"/>
                    <div class="faTrash">
                    <fa-icon [icon]="faTrash" class="dismiss-icon" (click)="dismissIconClick('addr_proof')"></fa-icon>
                </div>
            </div> -->
            <!-- </div>
            <br>
             <textarea class="form-control" rows="5" placeholder="Enter Comments/Observations/Recommendations(if any)" name="comment" [(ngModel)]="comment"></textarea> 
            <br>
            <div class="status-btn" fxLayout="row wrap" fxLayoutGap="10px" fxLayoutAlign="space-between"> -->
                 <div>
                    <!-- <div class="col-sm-12 offset-sm-10" >
                   <button class="scrutiny-user-btn doubtful-btn" (click)="updateApplicantStatus('observations_uploaded')">Observations Noted & Uploaded</button>
                 </div> -->
                 <div class="col-sm-12 offset-sm-5" >
                    <button class="scrutiny-user-btn doubtful-btn" (click)="updateRecordObservations(applicationData.id, applicationData.applicationId)"> Click to Record Observations </button>
                  </div>
                </div>
            </div>
        </div>
    <app-footer class="col-md-10 offset-sm-3" ></app-footer>
    </div>
    