import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { ConfirmDialogService } from 'src/app/shared/confirm-dialog/confirm-dialog.service';
import { ApiService } from 'src/services/api.service';
import { ApplicationService } from 'src/services/application.service';
import { StrorageService } from 'src/services/strorage.service';

@Component({
  selector: 'app-committee-active-status',
  templateUrl: './committee-active-status.component.html',
  styleUrls: ['./committee-active-status.component.scss']
})
export class CommitteeActiveStatusComponent implements OnInit {

  selectedData: any;
  public faSync = faSync;
  public statuses: any = [];
  public selectVillage : any;
  public statusOptionId: any;
  village: any;
  villages : any[];
  statusDataList: any = [];
  selectedVillages: number;
  user: any;
  show: boolean;
  villageId: any;
  public colors = ['#E74C3C', '#1ABB9C', '#3498DB', '#F39C12', '#9B59B6', '#50C1CF', '#34495E', '#E9967A', '#FA8072', '#4FE6F8','#E74C3C','#E74C3C', '#1ABB9C', '#3498DB', '#F39C12', '#9B59B6', '#50C1CF', '#34495E','#F1544A','#C9AC59','#999384','#ed7d31', '#5b9bd5','#1f4e79','#c378fc', '#0070c0','#18E2AB'];
  isRefreshing: boolean;
  staticsComponent: any;
  isCommitteeUser: any;
  public totalApplicantCount: number= 0;
  notificationType: any;
  allApplicationBtn: boolean = true;
  newNotificationBtn: boolean = false;
  oldNotificationBtn: boolean = false;

  constructor(private applicationService: ApplicationService,private router: Router, private cdr:ChangeDetectorRef,private confirmDialogService: ConfirmDialogService, private apiService:ApiService,
    private storageService: StrorageService, private _snackBar: MatSnackBar) { }

  ngOnInit() {
    this.user = this.storageService.getUser();
    this.fetchStatusCount();
    this.fetchVillages();
    this.getTotalApplicantCount();

  }
  refreshClick(){
    let currentComponent = this;
    this.isRefreshing = true;
    setTimeout(()=>{
      currentComponent.isRefreshing = false;
    }, 3000)
    // this.fetchAllStatuses();
   if(this.show){
     this.fetchVillages();
   }
  }

  getTotalApplicantCount(){
    this.totalApplicantCount = 0;
    for(let i = 0; i < this.statusDataList.length; i++){
      this.totalApplicantCount = this.totalApplicantCount + this.statusDataList[i].count;
    }
    return this.totalApplicantCount;
  }

  clickBack(){
    window.history.back()
  }

  notificationBtnClick(notificationType: any) {
    this.notificationType = notificationType;
    this.allApplicationBtn = false;
    if(this.notificationType == 'NEW') {
      this.newNotificationBtn = true;
      this.oldNotificationBtn = false;
      this.allApplicationBtn = false;
      // this.fetchAllStatuses();
    }
    if(this.notificationType == 'OLD') {
      this.newNotificationBtn = false;
      this.oldNotificationBtn = true;
      this.allApplicationBtn = false;
      // this.fetchAllStatuses();
    }
    if(this.notificationType == null) {
      this.newNotificationBtn = false;
      this.oldNotificationBtn = false;
      this.allApplicationBtn = true;
      //  this.fetchAllStatuses();
    }
    this.fetchStatusCount();
    this.getTotalApplicantCount();
 }

  fetchVillages() {
    this.applicationService.fetchAllVillages().subscribe(res=>{
      this.villages = res;
      this.villages.unshift({
        "id":"",
        "name":"All"
       });
    })  
  }

  fetchStatusCount() {
    this.applicationService.fetchApplicantCountByStatus(this.notificationType, this.villageId).subscribe(res=>{
      this.statusDataList = res;
    })  
  }
  navigateToDataTable(status: any){
    if(status.code == 'observations_uploaded'){
      this.router.navigate(['/committee-datatable'], { queryParams: { notificationType: this.notificationType, villageId: this.villageId, statusCode: status.code}});
    }else{
      return;
    }
  }
}
