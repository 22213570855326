import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { faTimes, faCheck, faTrash } from '@fortawesome/free-solid-svg-icons';
import { ConfirmationModalComponent } from 'src/app/confirmation-modal/confirmation-modal.component';
import { WorkbenchCommentDialogComponent } from 'src/app/field-visit-user/field-visit-workbench/workbench-comment-dialog/workbench-comment-dialog.component';
import { ConfirmDialogService } from 'src/app/shared/confirm-dialog/confirm-dialog.service';
import { ApplicationService } from 'src/services/application.service';
import { JccnoEncodeDecodeService } from 'src/services/jccno-encode-decode/jccno-encode-decode.service';

@Component({
  selector: 'app-leadership-users-judges-observation-workbench',
  templateUrl: './leadership-users-judges-observation-workbench.component.html',
  styleUrls: ['./leadership-users-judges-observation-workbench.component.scss']
})
export class LeadershipUsersJudgesObservationWorkbenchComponent implements OnInit {


  public faCheck = faCheck;


  public faTrash = faTrash
  application_id: any;
  applicationData: any;
  selectedLandType: any;
  selectedVillages: any;
  selectedPanchayat: any;
  commentTypes: any;
  public questions:any;
  public subQuestions:any;
  public observationId:any;
  public code:any;
  fielVisitCommentTypes: any;
  comment: any;
  files: any[] = [];
  applicantName:any;
  constructor(private applicationService: ApplicationService, private route: ActivatedRoute,
    private _snackBar: MatSnackBar, private router: Router,
    private workbenchDialogService: WorkbenchCommentDialogComponent, @Inject(DOCUMENT) private document,
    private dialog: MatDialog, private jccEncodeDecoder: JccnoEncodeDecodeService,
    private confirmDialogService: ConfirmDialogService,) {
    this.application_id = this.route.snapshot.queryParams['application_id'];
    this.applicantName = this.route.snapshot.queryParams['applicant_name']
  }

  ngOnInit(): void {
  
    this.updateObservationLeadershipUsers();
    }
    

  updateObservationLeadershipUsers(){
    this.applicationService.getAllObservationsByRoleCode().subscribe(res => {
      this.questions = res;
      this.subQuestions =res;
      console.log("Observations",res)
       })
  }

  close() {
    this.selectedVillages = this.route.snapshot.queryParams['village'];
    this.router.navigate(['/pi-user-dashboard']);

  }
  cancelClick() {
    this.close();
  }

  encloPage() {
    let fullURL = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '');
    window.open(fullURL + '#/preview-enclosure?application_id=' + this.application_id, '_blank');
  }

  clickBack() {
    window.history.back();
  }

  parseSelectedObservationQuestionAnswers = (questions: any) => {
    let selectedValues = [];
    for(let i =0;i < questions.length;i++) {
      if(questions[i].selectedValue) {
        let row = {};
        row['id'] = questions[i].id;
        row['selectedValue'] = questions[i].selectedValue;
        if(questions[i].subQuestions && questions[i].subQuestions.length > 0) {
          let subObservtions = this.parseSelectedObservationQuestionAnswers(questions[i].subQuestions);
          if(subObservtions && subObservtions.length > 0) {
            row['subObservations'] = subObservtions;
          }
        }
        selectedValues.push(row);
      }
    }
    return selectedValues;
  }

  updateApplicantStatus = (status: string) => {
    let selectedObservations = this.parseSelectedObservationQuestionAnswers(this.questions);
    let options = {};
    if (status == 'observations_uploaded') {
      options['message'] = 'Application is Observations Uploaded';
    }
    options['title'] = 'Confirm',
    options['cancelText'] = 'CANCEL',
    options['confirmText'] = 'OK'

    this.confirmDialogService.openDialog(options);
    this.confirmDialogService.confirmed().subscribe(confirmed => {
      if (confirmed) {
        let payload = {};
        payload['status_code'] = status;
        payload['comment'] = this.comment;
        payload['observations'] = selectedObservations;
        let formData = new FormData();
        formData.append('data', JSON.stringify(payload));
        if(this.files) {
          for (let file of this.files) {
            formData.append("file", file.data, file.data.name);
          }
        }
        this.applicationService.updateApplicantLeadershipStatus(this.application_id, formData).subscribe(res => {
          this.previousPage();
        })
      }
    });
  }
  previousPage(){
    this.router.navigate(['/leadership-users']);
  }


  openSnackbar = (message: string, action?: string) => {
    if (!action) {
      action = "Dismiss";
    }
    this._snackBar.open(message, action);
  }
  onFileChanged = (event: any) => {
    const file = {};
    file['name'] = event.target.name;
    file['data'] = event.target.files[0];
    this.files.push(file);
  }

  dismissIconClick = (id: any) => {
    (<HTMLInputElement>this.document.getElementById(id)).value = null;
    if (!this.files || this.files.length <= 0) {
      return;
    }
    this.files.map((row, index) => {
      if (row.name == id) {
        this.files.splice(index, 1);
      }
    })
  }
  
}


